import React, { useEffect, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { MezzaFormField, MezzaFormFieldProps } from '../MezzaForm';
import Input from '../../Input/Input';
import { PropertyCategory } from '../../../types/enums';
import { inputLabels } from '../../../data/labels';
import { TYPE_OPTIONS_BY_CATEGORY } from '../../../utils/mezza';

const Type = ({ control, formState, resetField }: MezzaFormFieldProps) => {
  const category = useWatch({ name: MezzaFormField.CATEGORY, control });
  const disabled = category === '';
  const options = useMemo(
    () =>
      category
        ? TYPE_OPTIONS_BY_CATEGORY[category as PropertyCategory]
        : [
            {
              key: '',
              value: 'Kérjük válasszon!'
            }
          ],
    [category]
  );

  useEffect(() => {
    if (formState.dirtyFields[MezzaFormField.CATEGORY]) {
      resetField(MezzaFormField.TYPE, { keepError: true });
    }
  }, [category]);

  return (
    <Controller
      control={control}
      name={MezzaFormField.TYPE}
      rules={{ required: 'Kötelező mező!' }}
      render={({ field }) => (
        <Input
          id={MezzaFormField.TYPE}
          value={(options.find(({ key }) => key === field.value) ?? options[0]).value}
          setValue={field.onChange}
          label={inputLabels.propertyType}
          options={options}
          disabled={disabled}
          error={
            formState.errors[MezzaFormField.TYPE]?.message
              ? `${formState.errors[MezzaFormField.TYPE]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

export default React.memo(Type);
