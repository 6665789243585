import { useCallback, useEffect, useMemo, useState } from 'react';

import Input, { InputProps } from '../Input/Input';

import arrow from './chevron-double-up.png';

import styles from './Dropdown.module.scss';

type DropdownProps = Pick<InputProps, 'value' | 'placeholder' | 'withSearch' | 'disabled'> &
  Required<Pick<InputProps, 'options' | 'setValue'>> & { id?: string };

const Dropdown = ({
  options,
  value = '',
  placeholder = '',
  id = 'dropdown',
  withSearch = false,
  disabled = false,
  setValue
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState<{ key: string; index: number }>({
    key: '',
    index: -1
  });
  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(
    () =>
      withSearch && search !== ''
        ? options.filter(({ value }) =>
            value.toLocaleLowerCase().startsWith(search.toLocaleLowerCase())
          )
        : options,
    [withSearch, options, search]
  );

  const onSelect = (value: string) => {
    setOpen(false);
    setSearch('');
    setValue(value);
  };

  const onClickOutsideHandler = useCallback(
    (e: MouseEvent) => {
      if (open && !document.getElementById(id)?.contains(e.target as Node)) {
        setOpen(false);
      }
    },
    [open, id]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (open) {
        switch (e.key) {
          case ARROW_DOWN_KEY:
            e.preventDefault();
            const nextIndex = target.index + 1;
            if (options.length > nextIndex) {
              setTarget({ key: options[nextIndex].key, index: nextIndex });
            }
            break;
          case ARROW_UP_KEY:
            e.preventDefault();
            const prevIndex = target.index - 1;
            if (prevIndex >= 0) {
              setTarget({ key: options[prevIndex].key, index: prevIndex });
            }
            break;
          case ENTER_KEY:
            if (target.key && target.index >= 0) {
              onSelect(target.key);
            }
            break;
          default:
            const index = options.findIndex((it) => it.value.toLowerCase().startsWith(e.key));

            if (index >= 0) {
              setTarget({ key: options[index].key, index });
            }
        }
      }
    },
    [open, target, options]
  );

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutsideHandler);
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('mousedown', onClickOutsideHandler);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onClickOutsideHandler, onKeyDown]);

  if (disabled) {
    return (
      <div id={id} className={`${styles.wrapper} ${styles.disabled}`}>
        <div>
          {value || placeholder} <img src={arrow} alt="" />
        </div>
      </div>
    );
  }

  return (
    <div id={id} className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
      <div onClick={() => setOpen((wasOpen) => !wasOpen)}>
        {value || placeholder} <img src={arrow} alt="" />
      </div>
      {open && (
        <div className={styles.optionsWrapper}>
          {withSearch ? (
            <Input id="search" value={search} placeholder="Keresés" setValue={setSearch} />
          ) : null}
          <ul className={styles.options}>
            {filteredOptions.map(({ key, value }) => (
              <li
                key={key}
                onClick={() => onSelect(key)}
                className={target.key === key ? styles.targeted : ''}
              >
                {value}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const ARROW_DOWN_KEY = 'ArrowDown';
const ARROW_UP_KEY = 'ArrowUp';
const ENTER_KEY = 'Enter';

export default Dropdown;
