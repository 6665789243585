import { useCallback, useContext, useState } from 'react';
import isEqual from 'lodash.isequal';

import { NotificationContext } from '../../App';
import { titles, buttonLabels, errors, inputLabels, placeholders } from '../../data/labels';
import { createCalendarCategory, updateCalendarCategory } from '../../requests';
import { ButtonType, SnackbarType } from '../../types/enums';
import Popup from '../Popup/Popup';
import {
  checkLength,
  replaceEmptyStringFieldValueWithNull,
  validateItem
} from '../../utils/common';
import { Validation, CalendarCategory } from '../../types/types';
import TextArea from '../TextArea/TextArea';
import Input from '../Input/Input';
import ColorInput from './ColorInput';

interface CalendarCategoryFormProps {
  onExit: (shouldReload?: boolean) => void;
  initialCategory?: CalendarCategory;
}

type CalendarCategoryFormErrors = {
  name?: string;
  description?: string;
  color?: string;
};

const CalendarCategoryForm = ({
  onExit,
  initialCategory = EMPTY_CATEGORY
}: CalendarCategoryFormProps) => {
  const notify = useContext(NotificationContext);
  const [category, setCategory] = useState(initialCategory);
  const [formErrors, setFormErrors] = useState<CalendarCategoryFormErrors>(
    {} as CalendarCategoryFormErrors
  );

  const onSuccess = useCallback(() => {
    notify(
      category.id === -1
        ? 'Az új kategória sikeresen létrejött.'
        : 'A kategória frissítése sikeres volt.',
      SnackbarType.SUCCESS
    );
    onExit(true);
  }, [category, notify, onExit]);

  const onError = useCallback(() => {
    notify(
      category.id === -1
        ? 'Az új kategória létehozása nem sikerült.'
        : 'A kategória frissítése nem sikerült.',
      SnackbarType.ERROR
    );
  }, [category, notify]);

  const onSave = useCallback(async () => {
    const errors = validateItem(category, VALIDATIONS);
    setFormErrors(errors as CalendarCategoryFormErrors);

    if (Object.keys(errors).length === 0 && !isEqual(category, initialCategory)) {
      const handler = category.id !== -1 ? updateCalendarCategory : createCalendarCategory;
      handler(replaceEmptyStringFieldValueWithNull(category), onSuccess, onError);
    }
  }, [category, initialCategory, onError, onSuccess]);

  return (
    <Popup
      show
      title={category.id === -1 ? titles.newCategory : titles.editCategory}
      onHide={onExit}
      footerButtons={[
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: onExit,
          inverse: true
        },
        {
          title: buttonLabels.saveData,
          type: ButtonType.PRIMARY,
          action: onSave
        }
      ]}>
      <Input
        id="name"
        label={inputLabels.name}
        value={category.name ?? ''}
        setValue={(val) =>
          setCategory((it) => ({
            ...it,
            name: val
          }))
        }
        error={formErrors.name}
        placeholder={placeholders.calendarCategoryName}
      />
      <ColorInput
        error={formErrors.color}
        color={category.color}
        onChange={(color) => setCategory({ ...category, color })}
      />
      <TextArea
        id="description"
        label={inputLabels.description}
        value={category.description ?? ''}
        setValue={(val) =>
          setCategory((it) => ({
            ...it,
            description: val
          }))
        }
        placeholder={placeholders.calendarCategoryDescription}
        error={formErrors.description}
      />
    </Popup>
  );
};

const VALIDATIONS: Validation<CalendarCategory>[] = [
  {
    field: 'name',
    validate: (val: string) => checkLength(val, 2, 80),
    error: `${errors.mandatoryTextField}\r\nMinimum 2, maximum 80 karakter.`
  },
  {
    field: 'description',
    validate: (val: string) => val === '' || val === null || checkLength(val, 2, 200),
    error: `Minimum 2, maximum 200 karakter.`
  },
  {
    field: 'color',
    validate: (val: string) => checkLength(val, 2, 40),
    error: `Kötelező mező, kérjük válasszon színt.`
  }
];

const EMPTY_CATEGORY: CalendarCategory = {
  id: -1,
  name: '',
  description: '',
  color: ''
};

export default CalendarCategoryForm;
