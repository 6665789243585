export const LOCAL_STORAGE_TOKEN_KEY = 'token';
export const LOCAL_STORAGE_TOKEN_EXPIRE = 'tokenExpire';
export const LOCAL_STORAGE_TOKEN_EXPIRE_MFA = 'mfaTokenExpire';
export const LOCAL_STORAGE_MAP_DATA_VERSION_KEY = 'MAP_DATA_VERSION';
export const LOCAL_STORAGE_MAP_DATA_KEY = 'MAP_DATA';

export const MFA_TOKEN_EXPIRE_MINUTES = 10;
export const TOKEN_EXPIRE_MINUTES = 30;

export const DEFAULT_VISIBLE_ROW_COUNT = 10;
