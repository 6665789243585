const Test = () => {

  return (
    <button
      type="button"
      style={{maxWidth: "200px", padding: "8px", margin: "auto"}}
      onClick={() => {
        throw new Error("Sentry Test Error");
      }}
    >
      Break the world
    </button>
  )
};

export default Test;
