import React from 'react';
import { Controller } from 'react-hook-form';

import { MezzaFormField, MezzaFormFieldProps } from '../MezzaForm';
import { inputLabels } from '../../../data/labels';
import Input from '../../Input/Input';
import { isValidEmail } from '../../../utils/common';

const ClientEmail = ({ control, formState }: MezzaFormFieldProps) => {
  return (
    <Controller
      control={control}
      name={MezzaFormField.CLIENT_EMAIL}
      rules={rules}
      render={({ field }) => (
        <Input
          id={MezzaFormField.CLIENT_EMAIL}
          value={field.value}
          setValue={field.onChange}
          label={inputLabels.clientEmail}
          error={
            formState.errors[MezzaFormField.CLIENT_EMAIL]?.message
              ? `${formState.errors[MezzaFormField.CLIENT_EMAIL]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

const rules = {
  validate: {
    contact: (v: string) => !v || !!isValidEmail(v) || 'Az email formátuma nem megfelelő.'
  }
};

export default React.memo(ClientEmail);
