import { useContext, useEffect, useMemo, useState } from 'react';

import { NotificationContext } from '../../App';
import { buttonLabels, errors, inputLabels, placeholders } from '../../data/labels';
import useData from '../../hooks/useData';
import { fetchOffices, fetchUpdateArea } from '../../requests';
import { AreaStatus, ButtonType, SnackbarType } from '../../types/enums';
import Button from '../Button/Button';
import Input from '../Input/Input';
import { AREA_STATUS_LABELS, constructOfficeOptions } from '../../utils/common';
import { Area } from '../../types/types';

import styles from './AreaEditForm.module.scss';

interface AreaEditFormProps {
  area: Area;
  refresh: () => void;
}

const AreaEditForm = ({ area, refresh }: AreaEditFormProps) => {
  const notify = useContext(NotificationContext);
  const [offices] = useData(
    fetchOffices,
    'Hiba történt az Irodák letöltése során, kérjük próbálja később.'
  );
  const [code, setCode] = useState(area.code);
  const [status, setStatus] = useState(area.status);
  const [office, setOffice] = useState(area.office);
  const [officeError, setOfficeError] = useState('');
  const [codeError, setCodeError] = useState('');

  const OFFICE_OPTIONS = useMemo(
    () => [
      ...(status === AreaStatus.FREE ? [{ key: '', value: placeholders.select }] : []),
      ...constructOfficeOptions(offices)
    ],
    [offices, status]
  );

  useEffect(() => {
    setOfficeError('');

    if (status !== AreaStatus.FREE && !office) {
      setOfficeError(errors.officeIsMandatory);
    }

    if (office?.area && office.area.id !== area.id && status === AreaStatus.OCCUPIED) {
      setOfficeError(errors.officeAlreadyOccupied);
    }
  }, [status, office, area.id]);

  useEffect(() => {
    if (code.length < 3) {
      setCodeError(errors.codeShouldBeMin2Chars);
    } else {
      setCodeError('');
    }
  }, [code]);

  const hasAreaChanged = () =>
    area.code !== code || area.office?.id !== office?.id || area.status !== status;

  const validate = () => {
    if (!codeError && !officeError && hasAreaChanged()) {
      fetchUpdateArea(area.id, code, office?.id, status)
        .then(() => {
          refresh();
          notify('A terület sikeresen frissítve.', SnackbarType.SUCCESS);
        })
        .catch(() => {
          notify('A terület frissítése nem sikerült.', SnackbarType.ERROR);
        });
    }
  };

  return (
    <div className={styles.wrapper}>
      <Input
        id="code"
        value={code}
        setValue={setCode}
        label={inputLabels.areaCode}
        placeholder={placeholders.areaCode}
        error={codeError}
      />
      <Input
        id="status"
        value={AREA_STATUS_LABELS[status as AreaStatus]}
        setValue={(val) => setStatus(val as AreaStatus)}
        label={inputLabels.status}
        options={STATUS_OPTIONS}
      />
      <Input
        id="office"
        value={office?.name ?? placeholders.select}
        setValue={(val) => setOffice(offices.find((it) => it.id === +val))}
        label={inputLabels.linkOffice}
        options={OFFICE_OPTIONS}
        placeholder={placeholders.select}
        error={officeError}
      />
      <Button action={validate} type={ButtonType.SECONDARY}>
        {buttonLabels.saveSettings}
      </Button>
    </div>
  );
};

const STATUS_OPTIONS = [
  {
    key: AreaStatus.FREE,
    value: AREA_STATUS_LABELS[AreaStatus.FREE]
  },
  {
    key: AreaStatus.OPTION,
    value: AREA_STATUS_LABELS[AreaStatus.OPTION]
  },
  {
    key: AreaStatus.OCCUPIED,
    value: AREA_STATUS_LABELS[AreaStatus.OCCUPIED]
  },
  {
    key: AreaStatus.ADDITIONAL_AREA,
    value: AREA_STATUS_LABELS[AreaStatus.ADDITIONAL_AREA]
  }
];

export default AreaEditForm;
