import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from '../components/Input/Input';
import Layout from '../components/Layout/Layout';
import Popup from '../components/Popup/Popup';
import { fetchForgotPassword } from '../requests';
import { AppRoute, ButtonType } from '../types/enums';
import { isValidEmail } from '../utils/common';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);

  const onSend = useCallback(async () => {
    if (isValidEmail(email)) {
      fetchForgotPassword(email)
        .then(() => {
          setIsEmailSent(true);
        })
        .catch((e) => {
          // TODO: show some error
          console.error(e);
        });
    }
  }, [email]);

  return (
    <Layout bgSrc="../images/hero-image.jpg">
      {isEmailSent ? (
        <Popup
          show
          title="Jelszó megújítása"
          permanent
          footerButtons={[
            {
              title: 'Vissza a címlapra',
              action: () => navigate(AppRoute.LOGIN),
              type: ButtonType.PRIMARY
            }
          ]}
        >
          <p>
            A jelszó megújítási kérelmét fogadtuk. A rendszerünk hamarosan kiküld egy levelet az Ön
            által megadott e-mail címre, amiben megtalálja a jelszó megújításához szükséges
            instrukciókat.
            <br />
            <br />
            Kérjük ellenőrizze a Levélszemét mappáját is, mert egyes esetekben előfordulhat, hogy
            oda kerül a kiküldött rendszerüzenet.
          </p>
        </Popup>
      ) : (
        <Popup
          show
          title="Jelszó megújítása"
          permanent
          footerButtons={[
            {
              title: 'jelszó megújításának kérése',
              action: onSend,
              type: ButtonType.PRIMARY
            }
          ]}
        >
          <p>
            Kérjük, adja meg az e-mail címét és elküldjük a megadott címre a jelszó megújításához
            szükséges információkat.
          </p>
          <Input
            id="email"
            value={email}
            setValue={setEmail}
            label="E-mail cím"
            placeholder="Kérjük, adja meg a regisztrációnál használt e-mail címét."
          />
        </Popup>
      )}
    </Layout>
  );
};

export default ForgotPassword;
