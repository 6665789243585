import { ButtonType } from '../../types/enums';

import styles from './Button.module.scss';

export interface ButtonProps {
  type: ButtonType;
  children: React.ReactNode;
  action: () => void;
  inverse?: boolean;
}

const Button = ({ type, inverse = false, children, action }: ButtonProps) => {
  const btnStyle = inverse ? styleMapping[type].inverse : styleMapping[type].normal;

  return (
    <button onClick={action} className={btnStyle}>
      {children}
    </button>
  );
};

const styleMapping = {
  [ButtonType.PRIMARY]: {
    normal: styles.primary,
    inverse: styles.primaryInverse
  },
  [ButtonType.SECONDARY]: {
    normal: styles.secondary,
    inverse: styles.secondaryInverse
  }
};

export default Button;
