import { useEffect, useState } from 'react';

import { fetchMapData, fetchMapDataServerVersion } from '../requests';
import { MapData } from '../types/types';
import { LOCAL_STORAGE_MAP_DATA_VERSION_KEY, LOCAL_STORAGE_MAP_DATA_KEY } from '../data/constants';

const useMapData = () => {
  const [mapData, setMapData] = useState<MapData>(getStoredMapData());

  useEffect(() => {
    fetchMapDataServerVersion().then(({ data: { version } }) => {
      if (
        Number(version) > getCurrentLocalVersion() ||
        !mapData ||
        Object.values(mapData).every((it) => it.length === 0)
      ) {
        fetchMapData().then(({ data: { items } }) => {
          setMapData(items);
          setStoredMapData(items);
          setCurrentLocalVersion(version);
        });
      }
    });
  }, []);

  return mapData;
};

const getCurrentLocalVersion = () =>
  Number(localStorage.getItem(LOCAL_STORAGE_MAP_DATA_VERSION_KEY));

const setCurrentLocalVersion = (v: string) =>
  localStorage.setItem(LOCAL_STORAGE_MAP_DATA_VERSION_KEY, v);

const getStoredMapData = () => {
  try {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_MAP_DATA_KEY) ?? '');
  } catch {
    return {
      counties: [],
      settlements: [],
      districts: []
    };
  }
};

const setStoredMapData = (data: MapData) =>
  localStorage.setItem(LOCAL_STORAGE_MAP_DATA_KEY, JSON.stringify(data));

export default useMapData;
