import { buttonLabels, titles } from '../../data/labels';
import { AppRoute } from '../../types/enums';
import Sidebar from './Sidebar';
import SidebarMenuItem from './SidebarMenuItem';

import { ReactComponent as MapIcon } from './icons/map.svg';
import { ReactComponent as UsersIcon } from './icons/users.svg';
import { ReactComponent as OfficeIcon } from './icons/marker.svg';
import { ReactComponent as DocumentIcon } from './icons/document.svg';
import { ReactComponent as LogIcon } from './icons/log.svg';
import { ReactComponent as CalendarIcon } from './icons/calendar.svg';

const AdminSidebar = () => {
  return (
    <Sidebar title={titles.administration}>
      <>
        <SidebarMenuItem target={AppRoute.ADMIN_AREAS} icon={MapIcon}>
          {buttonLabels.areas}
        </SidebarMenuItem>
        <SidebarMenuItem target={AppRoute.ADMIN_OFFICES} icon={OfficeIcon}>
          {buttonLabels.offices}
        </SidebarMenuItem>
        <SidebarMenuItem target={AppRoute.ADMIN_USERS} icon={UsersIcon}>
          {buttonLabels.users}
        </SidebarMenuItem>
        <SidebarMenuItem target={AppRoute.ADMIN_DOCUMENT_CATEGORIES} icon={DocumentIcon}>
          {buttonLabels.documents}
        </SidebarMenuItem>
        <SidebarMenuItem target={AppRoute.MEZZA_LOG} icon={LogIcon}>
          {buttonLabels.mezzaLog}
        </SidebarMenuItem>
        <SidebarMenuItem target={AppRoute.ADMIN_CALENDAR_CATEGORIES} icon={CalendarIcon}>
          {buttonLabels.calendar}
        </SidebarMenuItem>
      </>
    </Sidebar>
  );
};

export default AdminSidebar;
