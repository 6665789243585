import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import AdminSidebar from '../../components/Sidebar/AdminSidebar';
import PaginatedTable from '../../components/Table/PaginatedTable';
import TableControllers from '../../components/Table/TableControllers';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../../data/constants';
import { buttonLabels, helperLabels, titles } from '../../data/labels';
import { DOCUMENTS_TABLE_COLUMN_CONFIG } from '../../data/tableDefs';
import useData from '../../hooks/useData';
import useFilter from '../../hooks/useFilter';
import { AppRoute, ButtonType, SnackbarType, SortOption } from '../../types/enums';
import { Document, DocumentCategory } from '../../types/types';
import {
  fetchDeleteDocument,
  fetchDocumentCategory,
  fetchDocuments,
  fetchDownloadDocument,
  fetchOpenDocument
} from '../../requests';
import Button from '../../components/Button/Button';
import DocumentForm from '../../components/DocumentForm/DocumentForm';
import DeleteForm from '../../components/DeleteForm/DeleteForm';
import { NotificationContext } from '../../App';
import { isDownloadActionVisible, isViewActionVisible } from '../../utils/common';

const AdminDocuments = () => {
  const navigate = useNavigate();
  const notify = useContext(NotificationContext);
  const { categoryId } = useParams<{ categoryId: string }>();
  const [category, setCategory] = useState<DocumentCategory>();
  const [documents, refreshDocuments] = useData(
    fetchDocuments,
    'Hiba történt a dokumentumok letöltése során, kérjük próbálja később.',
    { categoryId }
  );
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredDocuments = useFilter(documents, query);
  const [documentInEdit, setDocumentInEdit] = useState<Document>();
  const [documentToDelete, setDocumentToDelete] = useState<Document>();
  const [showDocumentEditor, setShowDocumentEditor] = useState(false);

  useEffect(() => {
    if (categoryId) {
      fetchDocumentCategory(Number(categoryId))
        .then((res) => {
          setCategory(res.data.documentCategory);
        })
        .catch(() => navigate(AppRoute.ADMIN_DOCUMENT_CATEGORIES));
    }
  }, [categoryId, navigate]);

  const onCloseEditForm = useCallback(
    (shouldReload = false) => {
      setShowDocumentEditor(false);
      setDocumentInEdit(undefined);

      shouldReload && refreshDocuments();
    },
    [refreshDocuments]
  );

  const onDownloadFail = useCallback(() => {
    notify('A dokumentum letöltése nem sikerült.', SnackbarType.ERROR);
  }, [notify]);

  const onDownloadSuccess = useCallback(() => {
    notify('A dokumentum letöltése sikeres volt.', SnackbarType.SUCCESS);
  }, [notify]);

  const onOpenFail = useCallback(() => {
    notify('A dokumentum megnyitása nem sikerült.', SnackbarType.ERROR);
  }, [notify]);

  const onOpenSuccess = useCallback(() => {
    notify('A dokumentum megnyitása sikeres volt.', SnackbarType.SUCCESS);
  }, [notify]);

  return (
    <>
      <AdminSidebar />
      <Layout>
        <h1>
          {titles.documents}/{category?.name}
        </h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}
        >
          <p className="mb-25">
            Ebben a menüpontban lehetősége van a dokumentumok feltöltésére, keresésére és
            módosítására.
          </p>
          <div style={{ marginBottom: '-50px' }}>
            <Button
              type={ButtonType.SECONDARY}
              inverse
              action={() => navigate(AppRoute.ADMIN_DOCUMENT_CATEGORIES)}
            >
              Vissza
            </Button>
          </div>
        </div>
        <TableControllers
          searchTitle={helperLabels.searchInDocuments}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        >
          <div style={{ marginBottom: '0.75rem' }}>
            <Button action={() => setShowDocumentEditor(true)} type={ButtonType.SECONDARY}>
              {buttonLabels.newDocument}
            </Button>
          </div>
        </TableControllers>
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredDocuments}
          columns={[
            DOCUMENTS_TABLE_COLUMN_CONFIG[0],
            {
              field: 'document_category_id',
              headerName: 'Kategória',
              minWidth: 120,
              width: 10,
              isSortable: true,
              renderer: () => <span>{category?.name}</span>
            },
            ...DOCUMENTS_TABLE_COLUMN_CONFIG.slice(2)
          ]}
          actions={[
            {
              title: buttonLabels.download,
              minWidth: 120,
              width: 10,
              actionHandler: (doc) => {
                fetchDownloadDocument(doc, onDownloadSuccess, onDownloadFail);
              },
              visible: isDownloadActionVisible
            },
            {
              title: buttonLabels.view,
              actionHandler: (doc) => {
                fetchOpenDocument(doc, onOpenSuccess, onOpenFail);
              },
              minWidth: 120,
              width: 10,
              visible: isViewActionVisible
            },
            {
              title: buttonLabels.delete,
              minWidth: 65,
              width: 8,
              actionHandler: (doc) => setDocumentToDelete(doc)
            },
            {
              title: buttonLabels.edit,
              minWidth: 100,
              width: 8,
              actionHandler: (doc) => {
                setDocumentInEdit(doc);
                setShowDocumentEditor(true);
              }
            }
          ]}
          defaultSortColumn="created_at"
          defaultSortOrder={SortOption.DESC}
        />
      </Layout>
      {showDocumentEditor && category && (
        <DocumentForm
          onExit={onCloseEditForm}
          categoryId={category.id}
          categoryName={category.name}
          initialDocument={documentInEdit}
        />
      )}
      {documentToDelete && (
        <DeleteForm
          title={titles.deleteDocument}
          subtitle={`Biztosan törölni kívánja ${documentToDelete.title} dokumentumot?`}
          onDelete={() => fetchDeleteDocument(documentToDelete.id).then(refreshDocuments)}
          onExit={() => setDocumentToDelete(undefined)}
        />
      )}
    </>
  );
};

export default AdminDocuments;
