import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { AppRoute } from '../types/enums';

const useHasSideBar = () => {
  const { pathname } = useLocation();

  const hasPageSidebar = useMemo(
    () => PAGES_WITH_SIDEBAR.some((route) => matchPath(route, pathname)),
    [pathname]
  );

  return hasPageSidebar;
};

const PAGES_WITH_SIDEBAR = [
  AppRoute.PROFILE,
  AppRoute.CHANGE_PASSWORD,
  AppRoute.ADMIN_AREAS,
  AppRoute.ADMIN_PROPERTY_GROUPS,
  AppRoute.ADMIN_OFFICES,
  AppRoute.ADMIN_USERS,
  AppRoute.MEZZA_INCOMING,
  AppRoute.MEZZA_OUTGOING,
  AppRoute.MEZZA_CLOSED,
  AppRoute.MEZZA_DETAILS,
  AppRoute.MEZZA_LOG,
  AppRoute.MEZZA_RULES,
  AppRoute.ADMIN_MEZZA_DETAILS,
  AppRoute.ADMIN_DOCUMENT_CATEGORIES,
  AppRoute.ADMIN_DOCUMENTS,
  AppRoute.DOCUMENT_CATEGORIES,
  AppRoute.DOCUMENTS,
  AppRoute.ADMIN_CALENDAR_CATEGORIES,
  AppRoute.ADMIN_CALENDAR_CATEGORY_EVENTS,
  AppRoute.CALENDAR
];

export default useHasSideBar;
