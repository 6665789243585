import React from 'react';
import { Controller } from 'react-hook-form';

import { MezzaFormField, MezzaFormFieldProps } from '../MezzaForm';
import { inputLabels } from '../../../data/labels';
import Input from '../../Input/Input';

const Stairs = ({ control }: MezzaFormFieldProps) => {
  return (
    <Controller
      control={control}
      name={MezzaFormField.STAIRS}
      render={({ field }) => (
        <Input
          id={MezzaFormField.STAIRS}
          value={field.value}
          setValue={field.onChange}
          label={inputLabels.stairs}
        />
      )}
    />
  );
};

export default React.memo(Stairs);
