import { createPortal } from 'react-dom';

import styles from './Sidebar.module.scss';

interface SidebarProps {
  title: string;
  children: React.ReactNode;
}

const Sidebar = ({ title, children }: SidebarProps) => {
  return createPortal(
    <div className={`${styles.sidebar} ${window.devicePixelRatio > 1 ? styles.scale : ''}`}>
      <div className={styles.title}>{title}</div>
      <div className={styles.menu}>{children}</div>
    </div>,
    document.getElementById('side-panel')!
  );
};

export default Sidebar;
