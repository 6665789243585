import React from 'react';
import { Controller } from 'react-hook-form';

import { MezzaFormField, MezzaFormFieldProps } from '../MezzaForm';
import Input from '../../Input/Input';
import { PropertyCategory } from '../../../types/enums';
import { inputLabels } from '../../../data/labels';

const Category = ({ control, formState }: MezzaFormFieldProps) => {
  return (
    <Controller
      control={control}
      name={MezzaFormField.CATEGORY}
      rules={{ required: 'Kötelező mező!' }}
      render={({ field }) => (
        <Input
          id={MezzaFormField.CATEGORY}
          value={
            (CATEGORY_OPTIONS.find(({ key }) => key === field.value) ?? CATEGORY_OPTIONS[0]).value
          }
          setValue={field.onChange}
          label={inputLabels.saleOrRent}
          options={CATEGORY_OPTIONS}
          error={
            formState.errors[MezzaFormField.CATEGORY]?.message
              ? `${formState.errors[MezzaFormField.CATEGORY]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

export const CATEGORY_OPTIONS = [
  {
    key: '',
    value: 'Kérjük válasszon!'
  },
  {
    key: PropertyCategory.SALE,
    value: 'Eladó'
  },
  {
    key: PropertyCategory.RENT,
    value: 'Kiadó'
  },
  {
    key: PropertyCategory.MANAGEMENT,
    value: 'Ingatlankezelés'
  }
];

export default React.memo(Category);
