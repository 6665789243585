import { TableColumnConfig } from '../../types/types';
import { TextAlignOptions } from '../../types/enums';

import styles from './Table.module.scss';

export type TableAction<T> = {
  title: string;
  minWidth: number;
  width: number;
  actionHandler: (row: T) => void;
  visible?: (row: T) => boolean;
};

export interface TableProps<T> {
  columns: TableColumnConfig<T>[];
  data: T[];
  actions: TableAction<T>[];
  gridColumnStyle: {
    display: string;
    gridTemplateColumns: string;
  };
}

function Table<T>({ columns, actions, data, gridColumnStyle }: TableProps<T>) {
  return (
    <div>
      {data.map((row: any, rowIdx) => (
        <div key={rowIdx} className={styles.row} style={gridColumnStyle}>
          {columns.map((col, idx) => (
            <Cell key={col.field + idx} value={String(row[col.field] ?? '')} data={row} {...col} />
          ))}
          <div className={styles.actionWrapper}>
            {actions
              .filter((action) => (action.visible ? action.visible(row) : true))
              .map((it) => (
                <ActionCell
                  key={it.title}
                  minWidth={it.minWidth}
                  actionHandler={() => it.actionHandler(row)}
                >
                  {it.title}
                </ActionCell>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}

interface CellProps<T> {
  value: string;
  minWidth: number;
  renderer?: TableColumnConfig<T>['renderer'];
  data: T;
  align?: TextAlignOptions;
}

function Cell<T>({
  minWidth,
  renderer,
  value,
  data,
  align = TextAlignOptions.CENTER
}: CellProps<T>) {
  return (
    <div
      style={{
        minWidth: minWidth / window.devicePixelRatio + 'px',
        textAlign: align
      }}
    >
      {renderer ? renderer(value ?? '-', data) : value || '-'}
    </div>
  );
}

interface ActionCellProps {
  children: string;
  actionHandler: () => void;
  minWidth: number;
}

const ActionCell = ({ children, actionHandler, minWidth }: ActionCellProps) => {
  return (
    <div
      onClick={actionHandler}
      className={styles.action}
      style={{
        minWidth: minWidth / window.devicePixelRatio + 'px'
      }}
    >
      <strong>{children}</strong>
    </div>
  );
};

export default Table;
