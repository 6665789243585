import { InputType } from '../../types/enums';
import styles from './FileInput.module.scss';
import { Document } from '../../types/types';
import removeIcon from './remove.svg';

interface FileInputProps {
  id: string;
  label?: string;
  error?: string;
  doc: Document;
  setValue?: (val: any) => void;
  onFileRemove: () => void;
}

const FileInput = ({
  id,
  label,
  error = '',
  doc,
  setValue = () => {},
  onFileRemove
}: FileInputProps) => {
  return (
    <div className={error ? styles.error : ''}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className={styles.container}>
        <p className={styles.fileName}>
          {doc.path
            ? doc.path
            : doc.document_file?.name
            ? doc.document_file.name
            : 'Nincs fájl kiválasztva'}
          <span
            className={styles.fileName__removeBtn}
            onClick={() => {
              const fileInput = document.getElementById(id) as HTMLInputElement;
              fileInput.value = '';
              onFileRemove();
            }}
          >
            <img src={removeIcon} alt="törlés" />
          </span>
        </p>
        <div className={styles.innerContainer}>
          <input
            onChange={(e) => {
              if (e.target.files) {
                setValue(e.target.files[0]);
              }
            }}
            id={id}
            type={InputType.FILE}
            name={id}
            className={styles.fileInput}
          />
          <button
            onClick={() => {
              document.getElementById(id)?.click();
            }}
            className={styles.fileButton}
          >
            TALLÓZÁS
          </button>
        </div>
      </div>
      {error && <div className={styles.errorText}>{error}</div>}
    </div>
  );
};

export default FileInput;
