import { useCallback, useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Popup from '../Popup/Popup';
import { buttonLabels, titles } from '../../data/labels';
import { AppRoute, ButtonType, MezzaActivity, SnackbarType } from '../../types/enums';
import { fetchMezzaNotification, updateMezzaNotification } from '../../requests';
import Checkbox from '../Checkbox/Checkbox';
import { NotificationContext, UserContext } from '../../App';

import settingsIcon from './settings-icon.svg';

import styles from './MezzaMessageSetup.module.scss';

const MezzaMessageSetup = ({ id }: { id: string }) => {
  const { user } = useContext(UserContext);
  const notify = useContext(NotificationContext);
  const [showEditor, setShowEditor] = useState(false);
  const [setup, setSetup] = useState({
    [MezzaActivity.ASSIGN]: true,
    [MezzaActivity.MESSAGE]: true,
    [MezzaActivity.STATE_UPDATE]: true
  });

  useEffect(() => {
    if (showEditor) {
      fetchMezzaNotification(id)
        .then(({ data }) => {
          setSetup(data);
        })
        .catch(() => {
          notify('Hiba történt a beállítások lekérése során!', SnackbarType.ERROR);
          setShowEditor(false);
        });
    }
  }, [id, showEditor]);

  const updateSetup = useCallback((value: boolean, key: MezzaActivity) => {
    setSetup((it) => ({ ...it, [key]: value }));
  }, []);

  const saveSetup = useCallback(() => {
    updateMezzaNotification(id, setup)
      .then(() => {
        notify('A beállításokat sikeresen mentettük.', SnackbarType.SUCCESS);
        setShowEditor(false);
      })
      .catch(() => {
        notify('Hiba történt a beállítások módosítása során!', SnackbarType.ERROR);
      });
  }, [id, setup]);

  return (
    <>
      <div
        className={styles.opener}
        onClick={() => {
          setShowEditor(true);
        }}
      >
        <img src={settingsIcon} />
        Üzenet beállítások
      </div>
      <Popup
        show={showEditor}
        onHide={() => setShowEditor(false)}
        title={titles.messageSetup}
        footerButtons={[
          {
            action: () => setShowEditor(false),
            title: buttonLabels.cancel,
            type: ButtonType.PRIMARY,
            inverse: true
          },
          {
            action: saveSetup,
            title: buttonLabels.save,
            type: ButtonType.PRIMARY
          }
        ]}
      >
        {!user?.notifiable && (
          <div className={styles.warning}>
            Az email értesítések ki vannak kapcsolva, ezt a{' '}
            <NavLink to={AppRoute.USER_NOTIFICATIONS}>Profil</NavLink> menüpontban lehet módosítani!
          </div>
        )}
        <p>Válassza ki, milyen üzeneteket szeretne kapni.</p>
        <div className={styles.checkboxes}>
          <Checkbox
            id="message-related"
            label="Üzenetekhez kapcsolódó"
            setValue={(val) => updateSetup(val, MezzaActivity.MESSAGE)}
            value={setup[MezzaActivity.MESSAGE]}
          />
          <Checkbox
            id="state-related"
            label="Státuszhoz kapcsolódó"
            setValue={(val) => updateSetup(val, MezzaActivity.STATE_UPDATE)}
            value={setup[MezzaActivity.STATE_UPDATE]}
          />
          <Checkbox
            id="assignment-related"
            label="Hozzárendeléshez kapcsolódó"
            setValue={(val) => updateSetup(val, MezzaActivity.ASSIGN)}
            value={setup[MezzaActivity.ASSIGN]}
          />
        </div>
      </Popup>
    </>
  );
};

export default MezzaMessageSetup;
