import { useCallback, useContext, useState } from 'react';

import TextArea from '../TextArea/TextArea';
import Button from '../Button/Button';
import { ButtonType, SnackbarType } from '../../types/enums';

import { ReactComponent as Close } from '../Popup/close.svg';

import styles from './NewMessage.module.scss';
import { sendMezzaMessage } from '../../requests';
import { NotificationContext } from '../../App';

interface NewMessageProps {
  id: string;
  onSuccess: () => void;
  hide: () => void;
}

const NewMessage = ({ id, hide, onSuccess }: NewMessageProps) => {
  const notify = useContext(NotificationContext);
  const [msg, setMsg] = useState('');

  const onSave = useCallback(() => {
    if (msg) {
      sendMezzaMessage(id, msg)
        .then(() => {
          notify('Az üzenet sikeresen elküldve.', SnackbarType.SUCCESS);
          onSuccess();
          hide();
        })
        .catch(() => {
          notify('Hiba történt az üzenet elküldése során.', SnackbarType.ERROR);
        });
    }
  }, [msg, id, notify, hide, onSuccess]);

  return (
    <div className={styles.newMessage}>
      <div className={styles.messageHeader}>
        <span>Új üzenet</span>
        <div>
          <Close onClick={hide} />
        </div>
      </div>
      <TextArea
        placeholder="Ide írja az üzenetet…"
        id="message"
        value={msg}
        setValue={setMsg}
        rows={16}
      />
      <div className={styles.newMessageButtonRow}>
        <Button type={ButtonType.PRIMARY} action={onSave}>
          Küldés
        </Button>
        <Button type={ButtonType.PRIMARY} inverse action={hide}>
          Mégsem
        </Button>
      </div>
    </div>
  );
};

export default NewMessage;
