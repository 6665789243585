import React, { useCallback, useEffect, useState } from 'react';

import Input from '../Input/Input';
import { FormRowBody } from '../../types/types';

import styles from './Form.module.scss';

interface FormProps<T> {
  data: T;
  rows: {
    [key: string]: FormRowBody;
  }[];
  readonly?: boolean;
  errors?: {
    [key in keyof T]: string;
  };
  onChange?: (data: T) => void;
}

function Form<T>({ data, rows, readonly = false, errors, onChange }: FormProps<T>) {
  const [formData, setFormData] = useState(data);

  const updateData = useCallback((fieldName: string, data: string) => {
    setFormData((oldData) => ({
      ...oldData,
      [fieldName]: data
    }));
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(formData);
    }
  }, [formData]);

  return (
    <>
      {rows.map((row, i) => (
        <div key={i} className={styles.formRow}>
          {Object.entries(row).map(([field, opts]) => (
            <Input
              key={field}
              id={field}
              value={String(formData[field as keyof T] ?? '')}
              setValue={(val) => updateData(field, val)}
              label={(opts as FormRowBody).header}
              placeholder={(opts as FormRowBody).placeholder}
              readOnly={readonly || (opts as FormRowBody).readonly}
              error={errors && errors[field as keyof T]}
            />
          ))}
        </div>
      ))}
    </>
  );
}

export default React.memo(Form);
