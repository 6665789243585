import { useEffect, useMemo, useRef, useState } from 'react';

import Layout from '../../components/Layout/Layout';
import Sidebar from '../../components/Sidebar/Sidebar';
import PaginatedTable from '../../components/Table/PaginatedTable';
import TableControllers from '../../components/Table/TableControllers';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../../data/constants';
import { titles, helperLabels, buttonLabels } from '../../data/labels';
import { CALENDAR_EVENT_TABLE_COLUMN_CONFIG_USERS } from '../../data/tableDefs';
import useFilter from '../../hooks/useFilter';
import { fetchCalendarEvents } from '../../requests';
import { CalendarEvent } from '../../types/types';
import useData from '../../hooks/useData';
import CalendarEventDetails from '../../components/CalendarEventDetails/CalendarEventDetails';
import CalendarView from '../../components/Calendar/Calendar';
import CalendarEventFilterBlock from '../../components/CalendarEventFilterBlock/CalendarEventFilterBlock';
import { getEventLegend } from '../../utils/common';

const Calendar = () => {
  const [events] = useData(fetchCalendarEvents);
  const resetFilters = useRef<() => {}>();
  const reApplyAdvancedFilters = useRef<() => CalendarEvent[]>();
  const [pageSize, setPageSize] = useState(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredEvents = useFilter(events, query);
  const [filteredByAdvancedFilters, setFilteredByAdvancedFilters] = useState(filteredEvents);
  const [eventInDetails, setEventInDetails] = useState<CalendarEvent>();
  const legend = useMemo(() => getEventLegend(events), [events]);
  const [calendarLimits, setCalendarLimits] = useState({
    start: '',
    end: ''
  });

  useEffect(() => {
    setFilteredByAdvancedFilters(filteredEvents);
    reApplyAdvancedFilters.current?.();
  }, [filteredEvents]);

  return (
    <>
      <Sidebar title={titles.calendar}>
        <></>
      </Sidebar>
      <Layout>
        <h1>{titles.calendar}</h1>
        <div>
          <p className="mb-25">Ebben a menüpontban lehetősége van az események megtekintésére.</p>
        </div>
        <CalendarView
          onViewChange={setCalendarLimits}
          events={events}
          legend={legend}
          showDetails={setEventInDetails}
        />
        <TableControllers
          searchTitle={helperLabels.searchInCalendarEvents}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
          resetFilters={resetFilters.current}
          renderFilters={() => (
            <CalendarEventFilterBlock
              events={filteredEvents}
              onChange={setFilteredByAdvancedFilters}
              resetFilters={resetFilters}
              calendarLimits={calendarLimits}
              reApplyAdvancedFilters={reApplyAdvancedFilters}
            />
          )}
        />
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredByAdvancedFilters}
          columns={CALENDAR_EVENT_TABLE_COLUMN_CONFIG_USERS}
          actions={[
            {
              title: buttonLabels.more,
              minWidth: 80,
              width: 7,
              actionHandler: setEventInDetails
            }
          ]}
          defaultSortColumn="start_date"
        />
      </Layout>
      {eventInDetails && (
        <CalendarEventDetails
          onClose={() => setEventInDetails(undefined)}
          event={eventInDetails}
          category={eventInDetails.calendar_category}
        />
      )}
    </>
  );
};

export const renderCategory = (_val: string | number, event: CalendarEvent) => (
  <div>{event.calendar_category.name}</div>
);

export default Calendar;
