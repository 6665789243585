import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NotificationContext, UserContext } from '../App';
import Input from '../components/Input/Input';
import Layout from '../components/Layout/Layout';
import Popup from '../components/Popup/Popup';
import { AppRoute, ButtonType, SnackbarType } from '../types/enums';
import { isMfaTokenValid, loginByCredentials, loginByToken } from '../utils/auth';

const LoginMfa = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const notify = useContext(NotificationContext);
  const [token, setToken] = useState('');

  useEffect(() => {
    if (!isMfaTokenValid()) {
      navigate(AppRoute.LOGIN);
    }
  }, []);

  const onLogin = useCallback(async () => {
    if (token) {
      const user = await loginByToken(token, notify, true);

      if (user) {
        setUser(user);
      }
    }
  }, [token]);

  const onResendToken = async () => {
    if (state.email && state.password) {
      const success = await loginByCredentials(state.email, state.password, notify);

      if (success) {
        notify('Az új kódot elküldtük a megadott email címre.', SnackbarType.SUCCESS);
      }
    }
  };

  return (
    <Layout bgSrc="../images/hero-image.jpg">
      <Popup
        show
        title="2 faktoros beléptetés"
        permanent
        footerButtons={[
          {
            title: 'Bejelentkezési kód elküldése',
            action: onLogin,
            type: ButtonType.PRIMARY
          }
        ]}
      >
        <p>
          <strong>A belépéshez adja meg az e-mailben megkapott bejelentkezési kódot.</strong>
        </p>
        <Input
          id="token"
          value={token}
          setValue={setToken}
          label="Bejelentkezési kód"
          placeholder="Kérjük, adja meg a bejelentkezési kódot."
        />
        <p style={{ marginTop: 30 }}>
          Új bejelentkezési kód igényléséhez&nbsp;
          <strong
            onClick={onResendToken}
            style={{
              color: '#1b7b2b',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
          >
            kattintson ide
          </strong>
          .
        </p>
      </Popup>
    </Layout>
  );
};

export default LoginMfa;
