import { SortOption, TextAlignOptions } from '../../types/enums';
import { Comparator, TableColumnConfig } from '../../types/types';
import { stringComparator } from '../../utils/common';

import arrowDown from './sort-down.svg';

import styles from './TableHeader.module.scss';

interface TableHeaderProps<T> {
  columns: TableColumnConfig<T>[];
  gridColumnStyle: {
    display: string;
    gridTemplateColumns: string;
  };
  sortedColumn: {
    fieldName: keyof T & string;
    sortOrder: SortOption;
  };
  onSortChange: (sort: {
    fieldName: keyof T & string;
    sortOrder: SortOption;
    comparator: Comparator;
  }) => void;
}

const SORT_DIRECTION_SEQUENCE: SortOption[] = [SortOption.NONE, SortOption.ASC, SortOption.DESC]; // no sort, a-z, z-a

function TableHeader<T>({
  columns,
  gridColumnStyle,
  sortedColumn,
  onSortChange
}: TableHeaderProps<T>) {
  const onSort = ({ field, comparator }: TableColumnConfig<T>) => {
    const oldSortIndex = SORT_DIRECTION_SEQUENCE.indexOf(sortedColumn.sortOrder);
    const sortOrder =
      field === sortedColumn.fieldName
        ? SORT_DIRECTION_SEQUENCE[(((oldSortIndex + 1) % 3) + 3) % 3]
        : SORT_DIRECTION_SEQUENCE[1];

    onSortChange({
      fieldName: field,
      sortOrder,
      comparator: comparator ?? stringComparator
    });
  };

  return (
    <div className={styles.tableHeader} style={gridColumnStyle}>
      {columns.map((it, idx) => (
        <HeaderCell
          key={it.field + idx}
          onClick={() => onSort(it)}
          sortOrder={sortedColumn.fieldName === it.field ? sortedColumn.sortOrder : SortOption.NONE}
          {...it}
        />
      ))}
    </div>
  );
}

interface HeaderCellProps {
  headerName: string;
  minWidth: number;
  sortOrder: SortOption;
  onClick: () => void;
  isSortable?: boolean;
  align?: TextAlignOptions;
}

const HeaderCell = ({
  headerName,
  minWidth,
  isSortable,
  sortOrder,
  onClick,
  align = TextAlignOptions.CENTER
}: HeaderCellProps) => {
  return (
    <div
      {...(isSortable ? { onClick } : {})}
      style={{
        minWidth: minWidth / window.devicePixelRatio + 'px',
        justifyContent: align,
        width: '100%'
      }}
    >
      {headerName} {isSortable && <SorterArrows order={sortOrder} />}
    </div>
  );
};

const SorterArrows = ({ order }: { order: SortOption }) => {
  return (
    <div>
      <img style={{ opacity: order === SortOption.DESC ? 0.3 : 1 }} src={arrowDown} alt="" />
      <img style={{ opacity: order === SortOption.ASC ? 0.3 : 1 }} src={arrowDown} alt="" />
    </div>
  );
};

export default TableHeader;
