import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Popup from '../Popup/Popup';
import { buttonLabels, inputLabels, placeholders } from '../../data/labels';
import { ButtonType, SnackbarType } from '../../types/enums';
import Input from '../Input/Input';
import { fetchOfficeUsers, updateMezzaAssignee } from '../../requests';
import useData from '../../hooks/useData';
import { Mezza, User } from '../../types/types';
import { NotificationContext, UserContext } from '../../App';
import { isIncomingMezza } from '../../utils/mezza';
import { isAdmin } from '../../utils/auth';

interface MezzaAssignProps {
  mezza: Mezza;
  hide: () => void;
  onChanged: () => void;
}

const MezzaAssign = ({ mezza, hide, onChanged }: MezzaAssignProps) => {
  const { user } = useContext(UserContext);
  const notify = useContext(NotificationContext);
  const [officeUsers] = useData(
    fetchOfficeUsers,
    'Hiba történt az irodához tartozó felhasználók letöltése közben!',
    user?.officeId || mezza.recipient_office?.id
  );
  const [selected, setSelected] = useState('');
  const [originalUserId, setOriginalUserId] = useState<string>();
  const [assigneeRole, setAssigneeRole] = useState<'to_user_id' | 'from_user_id'>();
  const users = useMemo(
    () =>
      officeUsers.map((u) => ({
        key: u.id.toString(),
        value: u.name
      })),
    [officeUsers]
  );

  useEffect(() => {
    if (user && users) {
      if (isIncomingMezza(mezza, user.id, user.officeId || -1) || isAdmin(user as User)) {
        setOriginalUserId(mezza.toUser?.id.toString());
        setAssigneeRole('to_user_id');
        setSelected(users.find((it) => it.key === mezza.toUser?.id.toString())?.key ?? '');
      } else {
        setOriginalUserId(mezza.fromUser?.id.toString());
        setAssigneeRole('from_user_id');
        setSelected(users.find((it) => it.key === mezza.fromUser?.id.toString())?.key ?? '');
      }
    }
  }, [user, users, mezza]);

  const onSaveChange = useCallback(() => {
    if (selected !== originalUserId && assigneeRole) {
      updateMezzaAssignee(mezza.id.toString(), selected, assigneeRole)
        .then(() => onChanged())
        .catch(() => {
          notify('A hozzárendelés módosítása nem sikerült.', SnackbarType.ERROR);
        });
    }
  }, [selected, assigneeRole, mezza, originalUserId, notify, onChanged]);

  return (
    <Popup
      show
      title="Mezza hozzárendelése"
      footerButtons={[
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: hide,
          inverse: true
        },
        {
          title: buttonLabels.assign,
          type: ButtonType.PRIMARY,
          action: onSaveChange
        }
      ]}
      onHide={hide}>
      <>
        <p>Válassza ki, kihez szeretné rendelni a mezzát.</p>
        <Input
          id="assign"
          value={users.find((u) => u.key === selected)?.value ?? 'Kérjük válasszon!'}
          setValue={setSelected}
          options={[{ key: '', value: 'Kérjük válasszon!' }, ...users]}
          label={inputLabels.name}
          placeholder={placeholders.name}
        />
      </>
    </Popup>
  );
};

export default MezzaAssign;
