import { useCallback, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import huLocale from '@fullcalendar/core/locales/hu';
import { DatesSetArg } from '@fullcalendar/core';

import { CalendarEvent, CalendarApiEvent } from '../../types/types';

import styles from './Calendar.module.scss';

interface CalendarProps {
  events: CalendarEvent[];
  legend: Record<string, string>;
  showDetails: (event: CalendarEvent) => void;
  onViewChange?: (limits: { start: string; end: string }) => void;
}

const Calendar = ({ events, showDetails, legend, onViewChange }: CalendarProps) => {
  const transformedEvents = useMemo(() => transformEventsForCalendar(events), [events]);

  const updateCalendarLimits = useCallback(
    ({ view: { currentStart, currentEnd } }: DatesSetArg) => {
      const nextDay = new Date(currentStart).setDate(currentStart.getDate() + 1);

      onViewChange?.({
        start: new Date(nextDay).toISOString().split('T')[0],
        end: currentEnd.toISOString().split('T')[0]
      });
    },
    [onViewChange]
  );

  return (
    <div className={styles.wrapper}>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{ start: 'prev title next', center: '', end: 'today' }}
        dayHeaderFormat={{ weekday: 'long' }}
        firstDay={1}
        locale={huLocale}
        fixedWeekCount={false}
        events={transformedEvents}
        eventDisplay="block"
        displayEventTime={false}
        eventClick={({ event }) => showDetails(event.extendedProps.original)}
        datesSet={updateCalendarLimits}
        contentHeight={600}
        dayMaxEvents={4}
      />
      <div className={styles.legend}>
        {Object.entries(legend).map(([color, name]) => (
          <div key={color} className={styles.legendEntry}>
            <div
              className={styles.legendColor}
              style={{
                backgroundColor: color
              }}></div>
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};

const getContrastYIQ = (hexcolor: string) => {
  var r = parseInt(hexcolor.substring(1, 3), 16);
  var g = parseInt(hexcolor.substring(3, 5), 16);
  var b = parseInt(hexcolor.substring(5, 7), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

const getDateTimeString = (date: string, time?: string) => (time ? `${date}T${time}` : `${date}`);

const DAY_IN_MS = 1000 * 60 * 60 * 24;

const transformEvent = (event: CalendarEvent) => ({
  original: event,
  title: event.name,
  start: getDateTimeString(event.start_date, event.start),
  end: getDateTimeString(event.start_date, event.end), // IMPORTANT: multi-day events are duplicated for calendar view
  allDay: !event.start && !event.end,
  backgroundColor: event.calendar_category.color,
  textColor: getContrastYIQ(event.calendar_category.color)
});

const multiplyMultiDayEvent = (event: CalendarEvent) => {
  const dayDiff =
    (new Date(event.end_date).valueOf() - new Date(event.start_date).valueOf()) / DAY_IN_MS;

  const virtualEvents = [];
  const startValue = new Date(event.start_date).valueOf();

  for (let i = 0; i <= dayDiff; i++) {
    const newStart = startValue + i * DAY_IN_MS;
    virtualEvents.push(
      transformEvent({
        ...event,
        start_date: new Date(newStart).toISOString().split('T')[0],
        start: i === 0 ? event.start : '',
        end: i === 0 ? '' : event.end
      })
    );
  }

  return virtualEvents;
};

const transformEventsForCalendar = (events: CalendarEvent[]): CalendarApiEvent[] =>
  events.reduce((all, event) => {
    if (event.start_date !== event.end_date) {
      return [...all, ...multiplyMultiDayEvent(event)];
    }

    return [...all, transformEvent(event)];
  }, [] as CalendarApiEvent[]);

export default Calendar;
