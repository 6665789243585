import { useCallback, useContext, useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';

import { NotificationContext } from '../../App';
import { buttonLabels, errors, inputLabels, placeholders, titles } from '../../data/labels';
import { fetchUpdateOrCreateDocument } from '../../requests';
import { ButtonType, InputType, SnackbarType, UserRole } from '../../types/enums';
import Popup from '../Popup/Popup';
import {
  checkLength,
  isPositiveInteger,
  replaceEmptyStringFieldValueWithNull,
  USER_ROLE_LABELS,
  isValidFileExtension,
  validateItem
} from '../../utils/common';
import { Document, Validation } from '../../types/types';
import Input from '../Input/Input';
import TextArea from '../TextArea/TextArea';
import FileInput from '../FileInput/FileInput';

interface DocumentFormProps {
  onExit: (shouldReload?: boolean) => void;
  initialDocument?: Document;
  categoryId: number;
  categoryName: string;
}
type DocumentFormErrors = Record<keyof Document, string>;

const DocumentForm = ({
  onExit,
  initialDocument = EMPTY_DOCUMENT,
  categoryId,
  categoryName
}: DocumentFormProps) => {
  const notify = useContext(NotificationContext);
  const [doc, setDoc] = useState({
    ...initialDocument,
    document_category_id: categoryId,
    document_file: undefined
  });
  const [formErrors, setFormErrors] = useState<DocumentFormErrors>({} as DocumentFormErrors);

  useEffect(() => {
    return () => {
      setFormErrors({} as DocumentFormErrors);
    };
  }, []);

  const onSuccess = useCallback(() => {
    notify(
      doc.id === -1
        ? 'Az új dokumentum sikeresen létrejött.'
        : 'A dokumentum frissítése sikeres volt.',
      SnackbarType.SUCCESS
    );
    onExit(true);
  }, [document, notify, onExit]);

  const onError = useCallback(() => {
    notify(
      doc.id === -1
        ? 'Az új dokumentum létehozása nem sikerült.'
        : 'A dokumentum frissítése nem sikerült.',
      SnackbarType.ERROR
    );
  }, [doc, notify]);

  const onSave = useCallback(async () => {
    const errors = validateItem(doc, getValidations(doc));
    setFormErrors(errors as DocumentFormErrors);

    if (Object.keys(errors).length === 0 && !isEqual(document, initialDocument)) {
      const completeDocument: Document = { ...doc };

      fetchUpdateOrCreateDocument(
        replaceEmptyStringFieldValueWithNull(completeDocument),
        onSuccess,
        onError
      );
    }
  }, [doc, initialDocument, onError, onSuccess]);

  return (
    <Popup
      show
      title={doc.id === -1 ? titles.createDocument : titles.editDocument}
      onHide={onExit}
      footerButtons={[
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: onExit,
          inverse: true
        },
        {
          title: buttonLabels.saveData,
          type: ButtonType.PRIMARY,
          action: onSave
        }
      ]}
    >
      <Input
        id="title"
        label={inputLabels.documentTitle}
        value={doc.title ?? ''}
        setValue={(val) => setDoc((doc) => ({ ...doc, title: val }))}
        error={formErrors.title}
        placeholder={placeholders.documentTitle}
      />
      <TextArea
        id="description"
        label={inputLabels.description}
        value={doc.description ?? ''}
        setValue={(val) => setDoc((doc) => ({ ...doc, description: val }))}
        placeholder={placeholders.documentDescription}
        error={formErrors.description}
      />
      <Input
        id="categoryId"
        value={categoryName ?? ''}
        label={inputLabels.documentCategoryId}
        readOnly
      />
      <Input
        id="user_role"
        type={InputType.RADIO}
        value={
          (
            ACCESS_RIGHTS.find(({ key }) => key === doc.user_role) ?? {
              value: ''
            }
          ).value
        }
        setValue={(val) => setDoc((doc) => ({ ...doc, user_role: val }))}
        label={inputLabels.userRole}
        options={ACCESS_RIGHTS}
        error={formErrors.user_role}
      />
      <FileInput
        id={'document_file'}
        label={inputLabels.file}
        doc={doc}
        setValue={(val) => setDoc({ ...doc, path: '', document_file: val })}
        onFileRemove={() =>
          setDoc({
            ...doc,
            document_file: undefined,
            path: ''
          })
        }
        error={formErrors.document_file}
      />
    </Popup>
  );
};

const getValidations = (doc: Document): Validation<Document>[] => [
  {
    field: 'title',
    validate: (val: string) => checkLength(val, 2, 50),
    error: `${errors.mandatoryTextField}\r\nMinimum 2, maximum 50 karakter.`
  },
  {
    field: 'description',
    validate: (val: string) => checkLength(val, 2, 200),
    error: `${errors.mandatoryTextField}\r\nMinimum 2, maximum 200 karakter.`
  },
  {
    field: 'document_category_id',
    validate: (val: number) => isPositiveInteger(val),
    error: 'Érvénytelen kategória'
  },
  {
    field: 'user_role',
    validate: (val: string) => val !== '',
    error: 'A felhasználói szint kiválasztása kötelező.'
  },
  {
    field: 'document_file',
    validate: (val: File | undefined) =>
      (val !== undefined || doc.path !== '') &&
      (val !== undefined
        ? isValidFileExtension(val, [
            'pdf',
            'docx',
            'docm',
            'dotx',
            'xlsm',
            'xlsx',
            'xltx',
            'jpg',
            'jpeg',
            'png',
            'bmp',
            'gif',
            'svg',
            'webp',
            'ai'
          ])
        : true),
    error:
      'Fájl feltöltése kötelező.\r\nMegengedett kiterjesztések: pdf,docx,docm,dotx,xlsm,xlsx,xltx,jpg,jpeg,png,bmp,gif,svg,webp,ai'
  }
];

const EMPTY_DOCUMENT: Document = {
  id: -1,
  title: '',
  description: '',
  path: '',
  document_category_id: 0,
  user_role: '',
  created_at: ''
};

const ACCESS_RIGHTS = [
  { key: UserRole.USER, value: USER_ROLE_LABELS[UserRole.USER] },
  {
    key: UserRole.AREA_MANAGER,
    value: USER_ROLE_LABELS[UserRole.AREA_MANAGER]
  },
  {
    key: UserRole.OFFICE_MANAGER,
    value: USER_ROLE_LABELS[UserRole.OFFICE_MANAGER]
  }
];

export default DocumentForm;
