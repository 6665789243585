import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Sidebar from '../../components/Sidebar/Sidebar';
import TableControllers from '../../components/Table/TableControllers';
import { helperLabels, titles } from '../../data/labels';
import { AppRoute } from '../../types/enums';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../../data/constants';
import useFilter from '../../hooks/useFilter';
import PaginatedTable from '../../components/Table/PaginatedTable';
import useData from '../../hooks/useData';
import { fetchDocumentCategories } from '../../requests';
import { DOCUMENT_CATEGORIES_TABLE_COLUMN_CONFIG } from '../../data/tableDefs';
import { DocumentCategory } from '../../types/types';

const DocumentCategories = () => {
  const [documentCategoryList] = useData(
    fetchDocumentCategories,
    'Hiba történt a kategóriák letöltése során, kérjük próbálja később.'
  );

  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredDocumentCategories = useFilter(documentCategoryList, query);

  return (
    <>
      <Sidebar title={titles.documents}>
        <></>
      </Sidebar>
      <Layout>
        <h1>{titles.categories}</h1>
        <p className="mb-25">Ebben a menüpontban lehetősége van kategóriák kiválasztására.</p>
        <TableControllers
          searchTitle={helperLabels.searchInDocumentCategories}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        />
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredDocumentCategories}
          columns={DOCUMENT_CATEGORIES_TABLE_COLUMN_CONFIG}
          actions={[
            {
              title: 'Tovább',
              actionHandler: (documentCategory: DocumentCategory) =>
                navigate(AppRoute.DOCUMENT_CATEGORIES + '/' + documentCategory.id),
              minWidth: 120,
              width: 10
            }
          ]}
          defaultSortColumn="name"
        />
      </Layout>
    </>
  );
};

export default DocumentCategories;
