import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import MezzaDetailLayout from '../../components/MezzaDetailLayout/MezzaDetailLayout';
import AdminSidebar from '../../components/Sidebar/AdminSidebar';

const MezzaAdminDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate(-1);

    return null;
  }

  return (
    <>
      <AdminSidebar />
      <Layout>
        <MezzaDetailLayout id={id} />
      </Layout>
    </>
  );
};

export default MezzaAdminDetails;
