import { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { NotificationContext } from '../App';

import Button from '../components/Button/Button';
import Input from '../components/Input/Input';
import Layout from '../components/Layout/Layout';
import Popup from '../components/Popup/Popup';
import { inputLabels, placeholders } from '../data/labels';
import { AppRoute, ButtonType, InputType } from '../types/enums';
import { loginByCredentials } from '../utils/auth';
import { isValidEmail } from '../utils/common';

const Login = () => {
  const navigate = useNavigate();
  const notify = useContext(NotificationContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onLogin = async () => {
    if (isValidEmail(email) && password) {
      const canMoveForward = await loginByCredentials(email, password, notify);

      if (canMoveForward) {
        navigate(AppRoute.LOGIN_MFA, {
          state: {
            email,
            password
          }
        });
      }
    }
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        onLogin();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onLogin]);

  return (
    <Layout bgSrc="./images/hero-image.jpg">
      <Popup title="Bejelentkezés" show permanent>
        <Input
          id="email"
          value={email}
          setValue={setEmail}
          label="E-mail cím"
          placeholder="Kérjük, adja meg az e-mail címét."
        />
        <Input
          id="password"
          type={InputType.PASSWORD}
          value={password}
          setValue={setPassword}
          label={inputLabels.password}
          placeholder={placeholders.password}
        />
        <div style={{ margin: '24px 0 52px 0' }}>
          <Button action={onLogin} type={ButtonType.PRIMARY}>
            Bejelentkezés
          </Button>
        </div>

        <NavLink type="link" to={AppRoute.FORGOT_PASSWORD}>
          Elfelejtettem a jelszavamat
        </NavLink>
      </Popup>
    </Layout>
  );
};

export default Login;
