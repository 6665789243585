import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormResetField,
  useForm,
  useWatch
} from 'react-hook-form';

import Input from '../Input/Input';
import { inputLabels } from '../../data/labels';
import { CalendarEvent } from '../../types/types';
import { InputType } from '../../types/enums';

import styles from './CalendarEventFilterBlock.module.scss';
import { getMonthFirstDay, getMonthLastDay } from '../../utils/common';

interface CalendarEventFilterBlockProps {
  events: CalendarEvent[];
  onChange: (events: CalendarEvent[]) => void;
  resetFilters: MutableRefObject<(() => void) | undefined>;
  reApplyAdvancedFilters: MutableRefObject<(() => void) | undefined>;
  calendarLimits: {
    start: string;
    end: string;
  };
}

const CalendarEventFilterBlock = ({
  events,
  onChange,
  resetFilters,
  calendarLimits,
  reApplyAdvancedFilters
}: CalendarEventFilterBlockProps) => {
  const form = useForm({
    mode: 'onSubmit',
    defaultValues: CALENDAR_EVENT_FILTER_BLOCK_DEFAULT_VALUES
  });

  useEffect(() => {
    resetFilters.current = form.reset;
  }, [form.reset]);

  useEffect(() => {
    form.setValue(CalendarEventFilterBlockField.START, calendarLimits.start);
    form.setValue(CalendarEventFilterBlockField.END, calendarLimits.end);
  }, [calendarLimits, form.setValue]);

  const [name, place, start, end, category]: [string, string, string, string, string] = useWatch({
    name: [
      CalendarEventFilterBlockField.NAME,
      CalendarEventFilterBlockField.PLACE,
      CalendarEventFilterBlockField.START,
      CalendarEventFilterBlockField.END,
      CalendarEventFilterBlockField.CATEGORY
    ],
    control: form.control
  });

  const filter = useCallback(() => {
    return [
      { value: name, filterFunc: filterByName },
      { value: place, filterFunc: filterByPlace },
      { value: start, filterFunc: filterByStart },
      { value: end, filterFunc: filterByEnd },
      { value: category, filterFunc: filterByCategory }
    ].reduce((res, { value, filterFunc }) => {
      if (value) {
        return [...res.filter(filterFunc(value))];
      }

      return res;
    }, events);
  }, [name, place, start, end, category, events]);

  useEffect(() => {
    reApplyAdvancedFilters.current = () => onChange(filter());
  }, [onChange, filter]);

  useEffect(() => {
    onChange(filter());
  }, [filter, onChange]);

  return (
    <div className={styles.wrapper}>
      <NameSelector events={events} {...form} />
      <PlaceSelector events={events} {...form} />
      <StartSelector events={events} {...form} />
      <EndSelector events={events} {...form} />
      <CategorySelector events={events} {...form} />
    </div>
  );
};

const filterByName = (name: string) => (event: CalendarEvent) => event.name === name;
const filterByPlace = (place: string) => (event: CalendarEvent) => event.place === place;
const filterByStart = (start: string) => (event: CalendarEvent) => event.start_date >= start;
const filterByEnd = (end: string) => (event: CalendarEvent) => event.end_date <= end;
const filterByCategory = (id: string) => (event: CalendarEvent) =>
  Number(event.calendar_category_id) === Number(id);

export interface CalendarEventFilterBlockFieldProps {
  control: Control<CalendarEventFilterBlockValues>;
  resetField: UseFormResetField<CalendarEventFilterBlockValues>;
  formState: FormState<CalendarEventFilterBlockValues>;
  events: CalendarEvent[];
}

export enum CalendarEventFilterBlockField {
  NAME = 'name',
  PLACE = 'place',
  START = 'start',
  END = 'end',
  CATEGORY = 'category'
}

const CALENDAR_EVENT_FILTER_BLOCK_DEFAULT_VALUES = {
  [CalendarEventFilterBlockField.NAME]: '',
  [CalendarEventFilterBlockField.PLACE]: '',
  [CalendarEventFilterBlockField.START]: getMonthFirstDay(),
  [CalendarEventFilterBlockField.END]: getMonthLastDay(),
  [CalendarEventFilterBlockField.CATEGORY]: ''
};

export type CalendarEventFilterBlockValues = {
  [CalendarEventFilterBlockField.NAME]: string;
  [CalendarEventFilterBlockField.PLACE]: string;
  [CalendarEventFilterBlockField.START]: string;
  [CalendarEventFilterBlockField.END]: string;
  [CalendarEventFilterBlockField.CATEGORY]: string;
};

const DEFAULT_OPTION = { key: '', value: 'Mind' };
const constructCategoryOptions = (events: CalendarEvent[]) => {
  const ids: number[] = [];

  return events
    .sort((a, b) => a.calendar_category.name.localeCompare(b.calendar_category.name))
    .reduce((options, { calendar_category }) => {
      if (ids.includes(calendar_category.id)) {
        return options;
      }

      ids.push(calendar_category.id);
      return [...options, { key: String(calendar_category.id), value: calendar_category.name }];
    }, [] as { key: string; value: string }[]);
};

const CategorySelector = ({ control, events }: CalendarEventFilterBlockFieldProps) => {
  const categoryOptions = useMemo(
    () => [DEFAULT_OPTION, ...constructCategoryOptions(events)],
    [events]
  );

  return (
    <Controller
      control={control}
      name={CalendarEventFilterBlockField.CATEGORY}
      render={({ field }) => (
        <Input
          id={CalendarEventFilterBlockField.CATEGORY}
          value={(categoryOptions.find(({ key }) => key === field.value) ?? DEFAULT_OPTION).value}
          setValue={field.onChange}
          label={inputLabels.category}
          options={categoryOptions}
        />
      )}
    />
  );
};

const constructNameOptions = (events: CalendarEvent[]) =>
  events
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ name }) => ({ key: name, value: name }));

const NameSelector = ({ control, events }: CalendarEventFilterBlockFieldProps) => {
  const nameOptions = useMemo(() => [DEFAULT_OPTION, ...constructNameOptions(events)], [events]);

  return (
    <Controller
      control={control}
      name={CalendarEventFilterBlockField.NAME}
      render={({ field }) => (
        <Input
          id={CalendarEventFilterBlockField.NAME}
          value={(nameOptions.find(({ key }) => key === field.value) ?? DEFAULT_OPTION).value}
          setValue={field.onChange}
          label={inputLabels.naming}
          options={nameOptions}
        />
      )}
    />
  );
};

const constructPlaceOptions = (events: CalendarEvent[]) =>
  events
    .filter((event) => !!event.place)
    .sort((a, b) => a.place!.localeCompare(b.place!))
    .map(({ place }) => ({ key: String(place), value: place as string }));

const PlaceSelector = ({ control, events }: CalendarEventFilterBlockFieldProps) => {
  const placeOptions = useMemo(() => [DEFAULT_OPTION, ...constructPlaceOptions(events)], [events]);

  return (
    <Controller
      control={control}
      name={CalendarEventFilterBlockField.PLACE}
      render={({ field }) => (
        <Input
          id={CalendarEventFilterBlockField.PLACE}
          value={(placeOptions.find(({ key }) => key === field.value) ?? DEFAULT_OPTION).value}
          setValue={field.onChange}
          label={inputLabels.place}
          options={placeOptions}
        />
      )}
    />
  );
};

const StartSelector = ({ control }: CalendarEventFilterBlockFieldProps) => {
  return (
    <Controller
      control={control}
      name={CalendarEventFilterBlockField.START}
      render={({ field }) => (
        <Input
          type={InputType.DATE}
          id={CalendarEventFilterBlockField.START}
          value={field.value}
          setValue={field.onChange}
          label={inputLabels.start}
        />
      )}
    />
  );
};

const EndSelector = ({ control }: CalendarEventFilterBlockFieldProps) => {
  return (
    <Controller
      control={control}
      name={CalendarEventFilterBlockField.END}
      render={({ field }) => (
        <Input
          type={InputType.DATE}
          id={CalendarEventFilterBlockField.END}
          value={field.value}
          setValue={field.onChange}
          label={inputLabels.end}
        />
      )}
    />
  );
};

export default CalendarEventFilterBlock;
