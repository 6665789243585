import React from 'react';
import { Controller } from 'react-hook-form';

import { inputLabels } from '../../../data/labels';
import { MezzaFormFieldProps, MezzaFormField } from '../MezzaForm';
import TextArea from '../../TextArea/TextArea';

const Note = ({ control, formState }: MezzaFormFieldProps) => {
  return (
    <Controller
      control={control}
      name={MezzaFormField.NOTE}
      rules={rules}
      render={({ field }) => (
        <TextArea
          id={MezzaFormField.NOTE}
          value={field.value}
          setValue={field.onChange}
          label={inputLabels.note}
          error={
            formState.errors[MezzaFormField.NOTE]?.message
              ? `${formState.errors[MezzaFormField.NOTE]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

const rules = {
  required: 'Kötelező mező, maximum 500 karakter!',
  maxLength: {
    value: 500,
    message: 'Kötelező mező, maximum 500 karakter!'
  }
};

export default React.memo(Note);
