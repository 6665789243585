import { useCallback, useState } from 'react';

import Button from '../components/Button/Button';
import DeleteForm from '../components/DeleteForm/DeleteForm';
import Layout from '../components/Layout/Layout';
import OfficeForm from '../components/OfficeForm/OfficeForm';
import AdminSidebar from '../components/Sidebar/AdminSidebar';
import PaginatedTable from '../components/Table/PaginatedTable';
import TableControllers from '../components/Table/TableControllers';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../data/constants';
import { buttonLabels, helperLabels, titles } from '../data/labels';
import { OFFICES_TABLE_COLUMNS_CONFIG_SMALL } from '../data/tableDefs';
import useData from '../hooks/useData';
import useFilter from '../hooks/useFilter';
import { fetchDeleteOffice, fetchOffices } from '../requests';
import { ButtonType } from '../types/enums';
import { Office } from '../types/types';

const Offices = () => {
  const [offices, refreshOffices] = useData(
    fetchOffices,
    'Hiba történt az Irodák letöltése során, kérjük próbálja később.'
  );
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredOffices = useFilter(offices, query);
  const [officeInEdit, setOfficeInEdit] = useState<Office>();
  const [officeToDelete, setOfficeToDelete] = useState<Office>();
  const [showOfficeEditor, setShowOfficeEditor] = useState(false);

  const onCloseEditForm = useCallback(
    (shouldReload = false) => {
      setShowOfficeEditor(false);
      setOfficeInEdit(undefined);

      shouldReload && refreshOffices();
    },
    [refreshOffices]
  );

  return (
    <>
      <AdminSidebar />
      <Layout>
        <h1>{titles.manageOffices}</h1>
        <p className="mb-25">
          Ebben a menüpontban lehetősége van az irodák adatainak keresésére és módosítására.
        </p>
        <TableControllers
          searchTitle={helperLabels.searchInOffices}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        >
          <div style={{ marginBottom: '0.75rem' }}>
            <Button action={() => setShowOfficeEditor(true)} type={ButtonType.SECONDARY}>
              {buttonLabels.newOffice}
            </Button>
          </div>
        </TableControllers>
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredOffices}
          columns={OFFICES_TABLE_COLUMNS_CONFIG_SMALL}
          actions={[
            {
              title: buttonLabels.delete,
              minWidth: 65,
              width: 6,
              actionHandler: (office) => setOfficeToDelete(office)
            },
            {
              title: buttonLabels.edit,
              minWidth: 130,
              width: 9,
              actionHandler: (office) => {
                setOfficeInEdit(office);
                setShowOfficeEditor(true);
              }
            }
          ]}
          defaultSortColumn="areaCode"
        />
      </Layout>
      {showOfficeEditor && <OfficeForm initialOffice={officeInEdit} onExit={onCloseEditForm} />}
      {officeToDelete && (
        <DeleteForm
          onExit={() => setOfficeToDelete(undefined)}
          title={titles.deleteOffice}
          subtitle={`Biztosan törölni kívánja a(z) ${officeToDelete.name} irodát?`}
          onDelete={() => fetchDeleteOffice(officeToDelete.id).then(refreshOffices)}
          canNotDelete={!officeToDelete.safeToDelete}
        />
      )}
    </>
  );
};

export default Offices;
