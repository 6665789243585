import { renderCategory } from '../pages/Calendar/Calendar';
import { renderWithColorStripe } from '../pages/Calendar/CalendarCategories';
import { renderEventTime } from '../pages/Calendar/CalendarCategoryEvents';
import { AreaStatus, TextAlignOptions, UserRole } from '../types/enums';
import {
  TableColumnConfig,
  Office,
  Area,
  PropertyGroup,
  User,
  DocumentCategory,
  Document,
  CalendarCategory,
  CalendarEvent
} from '../types/types';
import {
  areaStatusComparator,
  eventCategoryComparator,
  eventDateTimeComparator,
  numberComparator,
  renderAreaStatus,
  renderDateTime,
  renderInBold,
  renderLastLogin,
  renderUserRight
} from '../utils/common';

export const OFFICES_TABLE_COLUMNS_CONFIG: TableColumnConfig<Office>[] = [
  {
    field: 'areaCode',
    headerName: 'Terület kód',
    minWidth: 120,
    width: 10,
    isSortable: true,
    renderer: renderInBold,
    align: TextAlignOptions.LEFT
  },
  {
    field: 'name',
    headerName: 'Iroda neve',
    minWidth: 180,
    width: 15,
    isSortable: true
  },
  {
    field: 'address',
    headerName: 'Cím',
    minWidth: 200,
    width: 'auto',
    isSortable: true
  },
  {
    field: 'managerName',
    headerName: 'Irodavezető',
    minWidth: 150,
    width: 15,
    isSortable: true
  },
  {
    field: 'managerPhone',
    headerName: 'Telefonszám',
    minWidth: 120,
    width: 10
  },
  {
    field: 'managerEmail',
    headerName: 'E-mail cím',
    minWidth: 200,
    width: 20
  }
];

export const OFFICES_TABLE_COLUMNS_CONFIG_SMALL: TableColumnConfig<Office>[] = [
  {
    ...OFFICES_TABLE_COLUMNS_CONFIG[0],
    minWidth: 110,
    width: 10
  },
  {
    ...OFFICES_TABLE_COLUMNS_CONFIG[1],
    minWidth: 140,
    width: 13
  },
  {
    ...OFFICES_TABLE_COLUMNS_CONFIG[2],
    minWidth: 160,
    width: 18
  },
  {
    ...OFFICES_TABLE_COLUMNS_CONFIG[3],
    minWidth: 150,
    width: 15
  },
  {
    ...OFFICES_TABLE_COLUMNS_CONFIG[4],
    minWidth: 120,
    width: 10
  },
  {
    ...OFFICES_TABLE_COLUMNS_CONFIG[5],
    minWidth: 180,
    width: 'auto'
  }
];

export const AREAS_TABLE_COLUMNS_CONFIG: TableColumnConfig<Area>[] = [
  {
    field: 'code',
    headerName: 'Terület kód',
    minWidth: 150,
    width: 32,
    isSortable: true,
    renderer: renderInBold,
    align: TextAlignOptions.LEFT
  },
  {
    field: 'status',
    headerName: 'Állapot',
    minWidth: 100,
    width: 20,
    isSortable: true,
    renderer: (val) => renderAreaStatus(val as AreaStatus),
    comparator: areaStatusComparator
  },
  {
    field: 'officeName',
    headerName: 'Iroda',
    minWidth: 150,
    width: 'auto',
    isSortable: true
  }
];

export const PROPERTY_GROUPS_TABLE_COLUMNS_CONFIG: TableColumnConfig<PropertyGroup>[] = [
  {
    field: 'city',
    headerName: 'Település neve',
    minWidth: 160,
    width: 16,
    isSortable: true,
    renderer: renderInBold,
    align: TextAlignOptions.LEFT
  },
  {
    field: 'street',
    headerName: 'Közterület neve és jellege',
    minWidth: 200,
    width: 'auto',
    isSortable: true
  },
  {
    field: 'numbers_even',
    headerName: 'Páros',
    minWidth: 100,
    width: 10
  },
  {
    field: 'numbers_odd',
    headerName: 'Páratlan',
    minWidth: 100,
    width: 10
  },
  {
    field: 'estates_sum',
    headerName: 'Ingatlanszám',
    minWidth: 130,
    width: 13,
    isSortable: true,
    comparator: numberComparator
  },
  {
    field: 'estates_goverment',
    headerName: 'Ebből önkormányzati',
    minWidth: 130,
    width: 13,
    isSortable: true,
    comparator: numberComparator
  }
];

export const USERS_TABLE_COLUMNS_CONFIG: TableColumnConfig<User>[] = [
  {
    field: 'name',
    headerName: 'Név',
    minWidth: 120,
    width: 9,
    isSortable: true,
    renderer: renderInBold,
    align: TextAlignOptions.LEFT
  },
  {
    field: 'email',
    headerName: 'E-mail cím',
    minWidth: 180,
    width: 18
  },
  {
    field: 'phone_number',
    headerName: 'Telefonszám',
    minWidth: 120,
    width: 12
  },
  {
    field: 'areaCode',
    headerName: 'Terület kód',
    minWidth: 120,
    width: 8,
    isSortable: true
  },
  {
    field: 'officeName',
    headerName: 'Iroda neve',
    minWidth: 150,
    width: 'auto',
    isSortable: true
  },
  {
    field: 'role',
    headerName: 'Jogosultság',
    minWidth: 130,
    width: 11,
    isSortable: true,
    renderer: (val) => renderUserRight(val as UserRole)
  },
  {
    field: 'last_login',
    headerName: 'Utolsó bejelentkezés',
    minWidth: 150,
    width: 12,
    isSortable: true,
    renderer: (val) => renderLastLogin(val as string)
  }
];

export const DOCUMENT_CATEGORIES_TABLE_COLUMN_CONFIG: TableColumnConfig<DocumentCategory>[] = [
  {
    field: 'name',
    headerName: 'Név',
    minWidth: 150,
    width: 30,
    isSortable: true,
    renderer: renderInBold,
    align: TextAlignOptions.LEFT
  },
  {
    field: 'description',
    headerName: 'Leírás',
    minWidth: 250,
    width: 'auto',
    isSortable: true,
    align: TextAlignOptions.LEFT
  }
];

export const DOCUMENTS_TABLE_COLUMN_CONFIG: TableColumnConfig<Document>[] = [
  {
    field: 'title',
    headerName: 'Cím',
    minWidth: 120,
    width: 10,
    isSortable: true,
    renderer: renderInBold,
    align: TextAlignOptions.LEFT
  },
  {
    field: 'document_category_id',
    headerName: 'Kategória',
    minWidth: 120,
    width: 10,
    isSortable: true
  },
  {
    field: 'user_role',
    headerName: 'Jogosultság',
    minWidth: 120,
    width: 12,
    isSortable: true,
    renderer: (val) => renderUserRight(val as UserRole)
  },
  {
    field: 'created_at',
    headerName: 'Feltöltés dátuma',
    minWidth: 120,
    width: 15,
    isSortable: true,
    renderer: (val) => renderDateTime(val as string)
  },
  {
    field: 'description',
    headerName: 'Leírás',
    minWidth: 100,
    width: 'auto',
    isSortable: true,
    align: TextAlignOptions.LEFT
  }
];

export const CALENDAR_CATEGORIES_TABLE_COLUMN_CONFIG: TableColumnConfig<CalendarCategory>[] = [
  {
    field: 'name',
    headerName: 'Kategória neve',
    minWidth: 250,
    width: 'auto',
    isSortable: true,
    renderer: renderWithColorStripe,
    align: TextAlignOptions.LEFT
  },
  {
    field: 'description',
    headerName: 'Leírás',
    width: 40,
    minWidth: 120,
    isSortable: true,
    align: TextAlignOptions.LEFT
  }
];

export const CALENDAR_EVENT_TABLE_COLUMN_CONFIG: TableColumnConfig<CalendarEvent>[] = [
  {
    field: 'name',
    headerName: 'Elnevezés',
    minWidth: 250,
    width: 'auto',
    isSortable: true,
    renderer: (name, { calendar_category }) => renderWithColorStripe(name, calendar_category),
    align: TextAlignOptions.LEFT
  },
  {
    field: 'place',
    headerName: 'Helyszín',
    width: 20,
    minWidth: 100,
    isSortable: true,
    align: TextAlignOptions.LEFT
  },
  {
    field: 'start_date',
    headerName: 'Időpont',
    width: 25,
    minWidth: 100,
    isSortable: true,
    renderer: renderEventTime,
    align: TextAlignOptions.LEFT,
    comparator: eventDateTimeComparator
  }
];

export const CALENDAR_EVENT_TABLE_COLUMN_CONFIG_USERS: TableColumnConfig<CalendarEvent>[] = [
  ...CALENDAR_EVENT_TABLE_COLUMN_CONFIG,
  {
    field: 'id',
    headerName: 'Kategória',
    minWidth: 100,
    width: 20,
    isSortable: true,
    renderer: renderCategory,
    align: TextAlignOptions.LEFT,
    comparator: eventCategoryComparator
  }
];
