import React, { useEffect, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { MezzaFormField, MezzaFormFieldProps } from '../MezzaForm';
import Input from '../../Input/Input';
import { PropertyType } from '../../../types/enums';
import { inputLabels } from '../../../data/labels';
import { SUBTYPES_BY_TYPE } from '../../../utils/mezza';

const SubType = ({ control, resetField, formState }: MezzaFormFieldProps) => {
  const mainType = useWatch({ control, name: MezzaFormField.TYPE });
  const disabled = !mainType;

  useEffect(() => {
    if (formState.dirtyFields[MezzaFormField.TYPE]) {
      resetField(MezzaFormField.SUB_TYPE, { keepError: true });
    }
  }, [mainType]);

  const options = useMemo(() => {
    if (SUBTYPES_BY_TYPE[mainType as PropertyType]) {
      return SUBTYPES_BY_TYPE[mainType as PropertyType];
    }

    return [];
  }, [mainType]);

  return (
    <Controller
      control={control}
      name={MezzaFormField.SUB_TYPE}
      rules={{ required: 'Kötelező mező!' }}
      render={({ field }) => (
        <Input
          id={MezzaFormField.SUB_TYPE}
          value={
            (
              options.find(({ key }) => key === field.value) ?? {
                value: 'Kérjük válasszon!'
              }
            ).value
          }
          setValue={field.onChange}
          label={inputLabels.propertySubType}
          options={[{ key: '', value: 'Kérjük válasszon!' }, ...options]}
          disabled={disabled}
          error={
            formState.errors[MezzaFormField.SUB_TYPE]?.message
              ? `${formState.errors[MezzaFormField.SUB_TYPE]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

export default React.memo(SubType);
