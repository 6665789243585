import { useCallback, useEffect, useState } from 'react';

import { buttonLabels, inputLabels, placeholders } from '../../data/labels';
import { ButtonType } from '../../types/enums';
import Button from '../Button/Button';
import Input from '../Input/Input';

import styles from './Search.module.scss';

interface SearchProps {
  label: string;
  onChange: (val: string) => void;
  resetFilters?: () => void;
}

const Search = ({ label, onChange, resetFilters = () => {} }: SearchProps) => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    onChange(query);
  }, [query]);

  const onReset = useCallback(() => {
    setQuery('');
    resetFilters();
  }, [resetFilters]);

  return (
    <div>
      <div className={styles.searchLabel}>{label}</div>
      <div className={styles.controllers}>
        <Input
          id="search"
          value={query}
          setValue={setQuery}
          label={inputLabels.search}
          placeholder={placeholders.search}
        />
        <Button action={() => onChange(query)} type={ButtonType.SECONDARY}>
          {buttonLabels.search}
        </Button>
        <Button action={onReset} type={ButtonType.SECONDARY} inverse>
          {buttonLabels.resetToDefault}
        </Button>
      </div>
    </div>
  );
};

export default Search;
