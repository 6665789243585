import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import AdminSidebar from '../components/Sidebar/AdminSidebar';
import PaginatedTable from '../components/Table/PaginatedTable';
import TableControllers from '../components/Table/TableControllers';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../data/constants';
import { buttonLabels, helperLabels, titles } from '../data/labels';
import { AREAS_TABLE_COLUMNS_CONFIG } from '../data/tableDefs';
import useData from '../hooks/useData';
import useFilter from '../hooks/useFilter';
import { fetchAreas } from '../requests';
import { AppRoute } from '../types/enums';
import { Area } from '../types/types';

const Areas = () => {
  const navigate = useNavigate();
  const [areas] = useData(
    fetchAreas,
    'Hiba történt a Területek letöltése során, kérjük próbálja később.'
  );
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredOffices = useFilter(areas, query);

  const goToProperties = (area: Area) => {
    navigate(AppRoute.ADMIN_AREAS + '/' + area.id);
  };

  return (
    <>
      <AdminSidebar />
      <Layout>
        <h1>{titles.manageAreas}</h1>
        <p className="mb-25">
          Ebben a menüpontban lehetősége van a területek adatainak keresésére és módosítására.
        </p>
        <TableControllers
          searchTitle={helperLabels.searchInAreas}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        />
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredOffices}
          columns={AREAS_TABLE_COLUMNS_CONFIG}
          actions={[
            {
              title: buttonLabels.edit,
              minWidth: 140,
              width: 15,
              actionHandler: goToProperties
            }
          ]}
          defaultSortColumn="code"
        />
      </Layout>
    </>
  );
};

export default Areas;
