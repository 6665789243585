import { useCallback, useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { NotificationContext } from '../App';
import { SnackbarType } from '../types/enums';

type useDataProps<T> = (params?: any) => Promise<
  AxiosResponse<{
    items: T[];
  }>
>;

function useData<T>(
  fetchData: useDataProps<T>,
  errorMsg = 'Hiba történt, kérjük próbálja később.',
  params?: any
): [T[], () => void] {
  const notify = useContext(NotificationContext);
  const [items, setItems] = useState<T[]>([]);

  const refresh = useCallback(() => {
    fetchData(params)
      .then((res) => {
        setItems(res.data.items);
      })
      .catch((e) => {
        notify(errorMsg, SnackbarType.ERROR);
        console.error(e);
      });
  }, [fetchData, notify, errorMsg]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [items, refresh];
}

export default useData;
