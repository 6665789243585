import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import AdminSidebar from '../../components/Sidebar/AdminSidebar';
import PaginatedTable from '../../components/Table/PaginatedTable';
import TableControllers from '../../components/Table/TableControllers';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../../data/constants';
import { buttonLabels, helperLabels, titles } from '../../data/labels';
import { DOCUMENT_CATEGORIES_TABLE_COLUMN_CONFIG } from '../../data/tableDefs';
import useData from '../../hooks/useData';
import useFilter from '../../hooks/useFilter';
import { AppRoute, ButtonType } from '../../types/enums';
import { DocumentCategory } from '../../types/types';
import { fetchDeleteDocumentCategory, fetchDocumentCategories } from '../../requests';
import Button from '../../components/Button/Button';
import DocumentCategoryForm from '../../components/DocumentCategoryForm/DocumentCategoryForm';
import DeleteForm from '../../components/DeleteForm/DeleteForm';

const AdminDocumentCategories = () => {
  const navigate = useNavigate();
  const [documentCategories, refreshDocumentCategories] = useData(
    fetchDocumentCategories,
    'Hiba történt a Dokumentum kategóriák letöltése során, kérjük próbálja később.'
  );
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredDocumentCategories = useFilter(documentCategories, query);
  const [documentCategoryInEdit, setDocumentCategoryInEdit] = useState<DocumentCategory>();
  const [documentCategoryToDelete, setDocumentCategoryToDelete] = useState<DocumentCategory>();
  const [showCategoryEditor, setShowCategoryEditor] = useState(false);

  const goToDocuments = (documentCategory: DocumentCategory) => {
    navigate(AppRoute.ADMIN_DOCUMENT_CATEGORIES + '/' + documentCategory.id);
  };

  const onCloseEditForm = useCallback(
    (shouldReload = false) => {
      setShowCategoryEditor(false);
      setDocumentCategoryInEdit(undefined);

      shouldReload && refreshDocumentCategories();
    },
    [refreshDocumentCategories]
  );

  return (
    <>
      <AdminSidebar />
      <Layout>
        <h1>{titles.categories}</h1>
        <p className="mb-25">
          Ebben a menüpontban lehetősége van a dokumentum kategóriák keresésére, módosítására és
          törlésére.
        </p>
        <TableControllers
          searchTitle={helperLabels.searchInDocumentCategories}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        >
          <div style={{ marginBottom: '0.75rem' }}>
            <Button action={() => setShowCategoryEditor(true)} type={ButtonType.SECONDARY}>
              {buttonLabels.newDocumentCategory}
            </Button>
          </div>
        </TableControllers>
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredDocumentCategories}
          columns={DOCUMENT_CATEGORIES_TABLE_COLUMN_CONFIG}
          actions={[
            {
              title: buttonLabels.more,
              minWidth: 80,
              width: 10,
              actionHandler: goToDocuments
            },
            {
              title: buttonLabels.delete,
              minWidth: 65,
              width: 10,
              actionHandler: (documentCategory) => setDocumentCategoryToDelete(documentCategory)
            },
            {
              title: buttonLabels.edit,
              minWidth: 130,
              width: 10,
              actionHandler: (documentCategory) => {
                setDocumentCategoryInEdit(documentCategory);
                setShowCategoryEditor(true);
              }
            }
          ]}
          defaultSortColumn="name"
        />
      </Layout>
      {showCategoryEditor && (
        <DocumentCategoryForm
          onExit={onCloseEditForm}
          initialDocumentCategory={documentCategoryInEdit}
        />
      )}
      {documentCategoryToDelete && (
        <DeleteForm
          title={titles.deleteDocumentCategory}
          subtitle={`Biztosan törölni kívánja ${documentCategoryToDelete.name} kategóriát?`}
          onDelete={() =>
            fetchDeleteDocumentCategory(documentCategoryToDelete.id).then(refreshDocumentCategories)
          }
          onExit={() => setDocumentCategoryToDelete(undefined)}
        />
      )}
    </>
  );
};

export default AdminDocumentCategories;
