import Button, { ButtonProps } from '../Button/Button';
import close from './close.svg';

import styles from './Popup.module.scss';

interface FooterButton extends Omit<ButtonProps, 'children'> {
  title: string;
}

interface PopupProps {
  show: boolean;
  title: string;
  children: React.ReactNode;
  onHide?: () => void;
  footerButtons?: FooterButton[];
  permanent?: boolean;
}

const Popup = ({ show, title, children, onHide, footerButtons = [], permanent }: PopupProps) => {
  if (!show) return null;

  return (
    <div className={styles.overlay} style={{ backgroundColor: '#00000080' }}>
      <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
        {!permanent && <img src={close} onClick={() => onHide?.()} />}
        <h2>{title}</h2>
        {children}
        <div className={styles.footerButtons}>
          {footerButtons.map(({ title, ...btnProps }, i) => (
            <Button key={i} {...btnProps}>
              {title}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Popup;
