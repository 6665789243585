import React, { useCallback, useContext, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { mezzaStatusTextByState } from '../../utils/mezza';
import { Mezza } from '../../types/types';
import { UserContext } from '../../App';
import { hasMezzaStatusEditAccess } from '../../utils/auth';
import MezzaStateUpdater from '../MezzaStateUpdater/MezzaStateUpdater';
import { AppRoute } from '../../types/enums';

import editIcon from './edit-icon.png';

import styles from './MezzaStatus.module.scss';

const MezzaStatus: React.FC<{
  mezza: Mezza;
}> = ({ mezza }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [showEditor, setShowEditor] = useState(false);
  const editable = useMemo(() => user && hasMezzaStatusEditAccess(user, mezza), [user, mezza]);

  const hide = useCallback(() => {
    setShowEditor(false);
  }, []);

  const onViewDetails = useCallback(() => {
    navigate(generatePath(AppRoute.MEZZA_DETAILS, { id: mezza.id.toString() }));
  }, [navigate, mezza]);

  return (
    <>
      <div className={styles.status}>
        {mezzaStatusTextByState[mezza.state]}
        {editable ? <img alt="" src={editIcon} onClick={() => setShowEditor(true)} /> : null}
      </div>
      {mezza && showEditor && (
        <MezzaStateUpdater mezza={mezza} onChange={onViewDetails} hide={hide} />
      )}
    </>
  );
};

export default MezzaStatus;
