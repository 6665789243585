import React, { useEffect, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { inputLabels } from '../../../data/labels';
import Input from '../../Input/Input';
import { MezzaFormFieldProps, MezzaFormField } from '../MezzaForm';
import { MapData } from '../../../types/types';
import { constructMapDataOptions } from '../../../utils/common';

interface DistrictProps extends MezzaFormFieldProps {
  districts: MapData['districts'];
}

const District = ({ control, districts, resetField, formState }: DistrictProps) => {
  const settlement = useWatch({ control, name: MezzaFormField.SETTLEMENT });

  useEffect(() => {
    if (formState.dirtyFields[MezzaFormField.SETTLEMENT]) {
      resetField(MezzaFormField.DISTRICT, { keepError: true });
    }
  }, [settlement]);

  const options = useMemo(
    () =>
      constructMapDataOptions(districts.filter(({ pid }) => String(pid) === String(settlement))),
    [districts, settlement]
  );

  const disabled = !settlement || options.length === 0;

  return (
    <Controller
      control={control}
      name={MezzaFormField.DISTRICT}
      render={({ field }) => (
        <Input
          id={MezzaFormField.DISTRICT}
          value={
            (
              options.find(({ key }) => key === field.value) ?? {
                value: 'Kérjük válasszon!'
              }
            ).value
          }
          setValue={field.onChange}
          label={inputLabels.districts}
          options={[{ key: '', value: 'Kérjük válasszon!' }, ...options]}
          withSearch
          disabled={disabled}
          error={
            formState.errors[MezzaFormField.DISTRICT]?.message
              ? `${formState.errors[MezzaFormField.DISTRICT]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

export default React.memo(District);
