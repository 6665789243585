import { useCallback, useState } from 'react';

import Button from '../components/Button/Button';
import DeleteForm from '../components/DeleteForm/DeleteForm';
import Layout from '../components/Layout/Layout';
import AdminSidebar from '../components/Sidebar/AdminSidebar';
import PaginatedTable from '../components/Table/PaginatedTable';
import TableControllers from '../components/Table/TableControllers';
import UserForm from '../components/UserForm/UserForm';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../data/constants';
import { titles, buttonLabels, helperLabels } from '../data/labels';
import { USERS_TABLE_COLUMNS_CONFIG } from '../data/tableDefs';
import useData from '../hooks/useData';
import useFilter from '../hooks/useFilter';
import { fetchUsers, fetchDeleteUser } from '../requests';
import { ButtonType } from '../types/enums';
import { User } from '../types/types';

const Users = () => {
  const [users, refreshUsers] = useData(
    fetchUsers,
    'Hiba történt az Felhasználók letöltése során, kérjük próbálja később.'
  );
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredUsers = useFilter(users, query);
  const [userInEdit, setUserInEdit] = useState<User>();
  const [userToDelete, setUserToDelete] = useState<User>();
  const [showUserEditor, setShowUserEditor] = useState(false);

  const onCloseEditForm = useCallback(
    (shouldReload = false) => {
      setShowUserEditor(false);
      setUserInEdit(undefined);

      shouldReload && refreshUsers();
    },
    [refreshUsers]
  );

  return (
    <>
      <AdminSidebar />
      <Layout>
        <h1>{titles.manageUsers}</h1>
        <p className="mb-25">
          Ebben a menüpontban lehetősége van a felhasználók adatainak keresésére és módosítására.
        </p>
        <TableControllers
          searchTitle={helperLabels.searchInUsers}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        >
          <div style={{ marginBottom: '0.75rem' }}>
            <Button action={() => setShowUserEditor(true)} type={ButtonType.SECONDARY}>
              {buttonLabels.newUser}
            </Button>
          </div>
        </TableControllers>
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredUsers}
          columns={USERS_TABLE_COLUMNS_CONFIG}
          actions={[
            {
              title: buttonLabels.delete,
              minWidth: 65,
              width: 6,
              actionHandler: (user) => setUserToDelete(user)
            },
            {
              title: buttonLabels.edit,
              minWidth: 130,
              width: 9,
              actionHandler: (user) => {
                setUserInEdit(user);
                setShowUserEditor(true);
              }
            }
          ]}
          defaultSortColumn="name"
        />
      </Layout>
      {showUserEditor && (
        <UserForm onExit={onCloseEditForm} initialUser={userInEdit} users={users} />
      )}
      {userToDelete && (
        <DeleteForm
          onExit={() => setUserToDelete(undefined)}
          title={titles.deleteUser}
          subtitle={`Biztosan törölni kívánja ${userToDelete.name} felhasználót?`}
          onDelete={() => fetchDeleteUser(userToDelete.id).then(refreshUsers)}
        />
      )}
    </>
  );
};

export default Users;
