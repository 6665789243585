import { useContext, useState } from 'react';

import { NotificationContext } from '../../App';
import { buttonLabels, inputLabels, errors } from '../../data/labels';
import { ButtonType, SnackbarType } from '../../types/enums';
import Input from '../Input/Input';
import Popup from '../Popup/Popup';

interface DeleteFormProps {
  title: string;
  subtitle: string;
  canNotDelete?: boolean;
  onDelete: () => Promise<void>;
  onExit: () => void;
}

const DeleteForm = ({
  title,
  subtitle,
  canNotDelete = false,
  onDelete,
  onExit
}: DeleteFormProps) => {
  const notify = useContext(NotificationContext);
  const [verify, setVerify] = useState('');
  const [error, setError] = useState('');

  const handleDelete = () => {
    if (verify === 'DELETE') {
      onDelete()
        .then(() => {
          onExit();
          notify('A törlés sikeres volt.', SnackbarType.SUCCESS);
        })
        .catch(() => {
          notify('A törlés nem sikerült.', SnackbarType.ERROR);
        });
    } else {
      setError(errors.delete);
    }
  };

  return (
    <Popup
      show
      title={title}
      onHide={onExit}
      footerButtons={[
        ...(!canNotDelete
          ? [
              {
                title: buttonLabels.delete,
                type: ButtonType.PRIMARY,
                action: handleDelete
              }
            ]
          : []),
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: onExit,
          inverse: true
        }
      ]}
    >
      {canNotDelete ? (
        <p>
          Ez az iroda nem törölhető, mert "Foglalt", "Opció alatt" vagy "Kiegészítő terület"
          státuszú terület tartozik hozzá. A törléshez előbb szüntesse meg a terület(ek)
          hozzárendelését!
        </p>
      ) : (
        <>
          <p>{subtitle}</p>
          <Input
            id="delete"
            setValue={setVerify}
            value={verify}
            label={inputLabels.verifyDelete}
            error={error}
          />{' '}
        </>
      )}
    </Popup>
  );
};

export default DeleteForm;
