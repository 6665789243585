import { useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { UserContext } from '../../App';
import { canAccessAdminPage, destroyToken, hasMezzaAccess } from '../../utils/auth';
import { buttonLabels } from '../../data/labels';
import { AppRoute, ButtonType } from '../../types/enums';
import { isAdminPage, isActiveLink } from '../../utils/common';
import useHasSideBar from '../../hooks/useHasSideBar';
import Button from '../Button/Button';

import logo from './logo-technocasa.png';
import arrow from './chevron-double-up.png';
import userImage from './user.png';

import styles from './Header.module.scss';

const Header = () => {
  const { user, setUser } = useContext(UserContext);
  const hasPageSidebar = useHasSideBar();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={styles.header} style={user ? {} : { zIndex: 1 }}>
      {user ? (
        <img src={logo} alt="Tecnocasa" className={styles.logo} />
      ) : (
        <NavLink to={AppRoute.LOGIN}>
          <img src={logo} alt="Tecnocasa" className={styles.logo} />
        </NavLink>
      )}

      {user && (
        <div className={styles.rightMenu}>
          {hasMezzaAccess(user) ? (
            <Button
              type={ButtonType.PRIMARY}
              action={() => {
                navigate(AppRoute.MEZZA_INCOMING);
              }}
            >
              Mezza
            </Button>
          ) : null}
          {!isAdminPage() && (
            <>
              <NavLink
                to={AppRoute.DOCUMENT_CATEGORIES}
                style={
                  isActiveLink(AppRoute.DOCUMENT_CATEGORIES) || isActiveLink(AppRoute.DOCUMENTS)
                    ? { color: '#1B7B2B' }
                    : {}
                }
              >
                Dokumentumok
              </NavLink>
              <NavLink
                to={AppRoute.CALENDAR}
                style={isActiveLink(AppRoute.CALENDAR) ? { color: '#1B7B2B' } : {}}
              >
                Naptár
              </NavLink>
            </>
          )}
          {hasPageSidebar && (
            <NavLink
              to={
                canAccessAdminPage(user.role) && isAdminPage()
                  ? AppRoute.ADMIN_AREAS
                  : AppRoute.ROOT
              }
            >
              {buttonLabels.homePage}
            </NavLink>
          )}
          <div onClick={() => setIsUserMenuOpen((isOpen) => !isOpen)} className={styles.user}>
            <img src={userImage} alt="" />
            {isUserMenuOpen && (
              <div className={styles.activeMenu}>
                <div
                  className={styles.user}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsUserMenuOpen(false);
                  }}
                >
                  {user.name} <img src={arrow} alt="" />
                </div>
                <div style={{ padding: 0 }}>
                  <NavLink to={AppRoute.PROFILE}>{buttonLabels.profile}</NavLink>
                </div>
                <div
                  onClick={() => {
                    setUser(undefined);
                    destroyToken();
                  }}
                >
                  {buttonLabels.logout}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
