import React, { useCallback, useState } from 'react';

import { Mezza } from '../../types/types';
import Popup from '../Popup/Popup';
import { buttonLabels, titles } from '../../data/labels';
import { ButtonType } from '../../types/enums';
import MezzaDetails from '../MezzaDetails/MezzaDetails';
import { fetchMezza } from '../../requests';

import openerIcon from './opener-icon.png';

import styles from './MezzaDetailOpener.module.scss';

const MezzaDetailOpener: React.FC<{ mezza: Mezza }> = ({ mezza }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState(mezza);

  const onClose = useCallback(() => {
    setShowDetails(false);
  }, []);

  const onOpen = useCallback(() => {
    setDetails(mezza);
    setShowDetails(true);
    fetchMezza(mezza.id.toString()).then((res) => setDetails({ ...mezza, ...res.data }));
  }, [mezza]);

  return (
    <>
      <img onClick={onOpen} className={styles.opener} src={openerIcon} alt="Részletek" />
      {showDetails ? (
        <Popup
          show
          title={titles.info}
          onHide={onClose}
          footerButtons={[
            {
              title: buttonLabels.close,
              type: ButtonType.PRIMARY,
              action: onClose,
              inverse: true
            }
          ]}
        >
          <MezzaDetails mezza={details} />
        </Popup>
      ) : null}
    </>
  );
};

export default MezzaDetailOpener;
