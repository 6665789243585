import { useCallback, useContext, useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';

import { NotificationContext } from '../../App';
import { titles, buttonLabels, errors, inputLabels, placeholders } from '../../data/labels';
import { fetchUpdateOrCreatePropertyGroup } from '../../requests';
import { ButtonType, SnackbarType } from '../../types/enums';
import Form from '../Form/Form';
import Popup from '../Popup/Popup';
import { hasMinLength, isPositiveInteger, validateItem } from '../../utils/common';
import { FormRowBody, PropertyGroup, Validation } from '../../types/types';

interface PropertyFormProps {
  onExit: (shouldReload?: boolean) => void;
  areaId: number;
  initialProperty?: PropertyGroup;
}

const PropertyForm = ({ onExit, areaId, initialProperty = EMPTY_PROPERTY }: PropertyFormProps) => {
  const notify = useContext(NotificationContext);
  const [property, setProperty] = useState({
    ...initialProperty,
    area_id: areaId
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    return () => {
      setFormErrors({});
    };
  }, []);

  const onSuccess = useCallback(() => {
    notify(
      property.id === -1
        ? 'Az új területcsoport sikeresen létrejött.'
        : 'A területcsoport frissítése sikeres volt.',
      SnackbarType.SUCCESS
    );
    onExit(true);
  }, [property, notify, onExit]);

  const onError = useCallback(() => {
    notify(
      property.id === -1
        ? 'Az új területcsoport létehozása nem sikerült.'
        : 'A területcsoport frissítése nem sikerült.',
      SnackbarType.ERROR
    );
  }, [property, notify]);

  const onSave = useCallback(() => {
    if (property) {
      const errors = validateItem(property, VALIDATIONS(property));
      setFormErrors(errors);

      if (Object.keys(errors).length === 0 && !isEqual(property, initialProperty)) {
        fetchUpdateOrCreatePropertyGroup(property, onSuccess, onError);
      }
    }
  }, [property, initialProperty, onError, onSuccess]);

  return (
    <Popup
      show
      title={property.id === -1 ? titles.createArea : titles.editArea}
      onHide={onExit}
      footerButtons={[
        {
          title: buttonLabels.saveData,
          type: ButtonType.PRIMARY,
          action: onSave
        },
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: onExit,
          inverse: true
        }
      ]}
    >
      <Form
        data={property}
        onChange={(val) => setProperty(val as PropertyGroup)}
        rows={PROPERTY_FORM_ROWS}
        errors={formErrors}
      />
    </Popup>
  );
};

const EMPTY_PROPERTY: PropertyGroup = {
  id: -1,
  area_id: 0,
  city: '',
  street: '',
  numbers_even: '',
  numbers_odd: '',
  estates_sum: 0,
  estates_goverment: 0
};

const VALIDATIONS = (property: PropertyGroup): Validation<PropertyGroup>[] => [
  {
    field: 'city',
    validate: (val: string) => hasMinLength(val, 3),
    error: `${errors.mandatoryTextField}\r\nMinimum 3, maximum 190 karakter.`
  },
  {
    field: 'street',
    validate: (val: string) => hasMinLength(val, 3),
    error: `${errors.mandatoryTextField}\r\nMinimum 3, maximum 190 karakter.`
  },
  {
    field: 'numbers_even',
    validate: (val: string) => hasMinLength(val, 1),
    error: `${errors.mandatoryTextField}\r\nMinimum 1, maximum 190 karakter.`
  },
  {
    field: 'numbers_odd',
    validate: (val: string) => hasMinLength(val, 1),
    error: `${errors.mandatoryTextField}\r\nMinimum 1, maximum 190 karakter.`
  },
  {
    field: 'estates_sum',
    validate: (val: string) => isPositiveInteger(Number(val)),
    error: errors.mandatoryNumberField
  },
  {
    field: 'estates_goverment',
    validate: (val: string) =>
      isPositiveInteger(Number(val)) && Number(property.estates_sum) >= Number(val),
    error: `${errors.mandatoryNumberField}\r\nNem lehet nagyobb, mint az összes ingatlan.`
  }
];

const PROPERTY_FORM_ROWS: {
  [key: string]: FormRowBody;
}[] = [
  { city: { header: inputLabels.city, placeholder: placeholders.city } },
  { street: { header: inputLabels.street, placeholder: placeholders.street } },
  {
    numbers_even: { header: inputLabels.even, placeholder: placeholders.even },
    numbers_odd: { header: inputLabels.odd, placeholder: placeholders.odd }
  },
  {
    estates_sum: {
      header: inputLabels.numberOfEstates,
      placeholder: placeholders.numberOfEstates
    },
    estates_goverment: {
      header: inputLabels.numberOfEstatesGovt,
      placeholder: placeholders.numberOfEstatesGovt
    }
  }
];

export default PropertyForm;
