import React, { useCallback, useContext, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import Button from '../../components/Button/Button';
import Layout from '../../components/Layout/Layout';
import MezzaSidebar from '../../components/Sidebar/MezzaSidebar';
import TableControllers from '../../components/Table/TableControllers';
import { titles, helperLabels, buttonLabels } from '../../data/labels';
import { AppRoute, ButtonType, SortOption } from '../../types/enums';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../../data/constants';
import useFilter from '../../hooks/useFilter';
import PaginatedTable from '../../components/Table/PaginatedTable';
import { Mezza, TableColumnConfig, User } from '../../types/types';
import MezzaForm from '../../components/MezzaForm/MezzaForm';
import MezzaDetailOpener from '../../components/MezzaDetailOpener/MezzaDetailOpener';
import MezzaStatus from '../../components/MezzaStatus/MezzaStatus';
import { hasMezzaCreateAccess } from '../../utils/auth';
import { UserContext } from '../../App';
import { mezzaStateComparator, numberComparator } from '../../utils/common';
import { formatMezzas } from '../../utils/mezza';
import useMapData from '../../hooks/useMapData';

interface CommonProps extends React.PropsWithChildren {
  mezzaList: Mezza[];
  refresh: () => void;
}

const Common = ({ mezzaList = [], refresh }: CommonProps) => {
  const navigate = useNavigate();
  const mapData = useMapData();
  const { user } = useContext(UserContext);
  const formattedMezzaList = useMemo(() => formatMezzas(mezzaList, mapData), [mezzaList, mapData]);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredMezzas = useFilter(formattedMezzaList, query);
  const [showMezzaEditor, setShowMezzaEditor] = useState(false);

  const onCloseEditForm = useCallback(() => {
    setShowMezzaEditor(false);
  }, []);

  const onViewDetails = useCallback(
    ({ id }: Mezza) => {
      navigate(generatePath(AppRoute.MEZZA_DETAILS, { id: id.toString() }));
    },
    [navigate]
  );

  return (
    <>
      <MezzaSidebar />
      <Layout>
        <h1>{titles.mezzaSystem}</h1>
        <p className="mb-25">
          Ebben a menüpontban láthatja a mezza rendszerben megosztott bejegyzéseket.
        </p>
        <TableControllers
          searchTitle={helperLabels.searchInMezzas}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        >
          {hasMezzaCreateAccess(user as User) && (
            <div style={{ marginBottom: '0.75rem' }}>
              <Button
                action={() => {
                  setShowMezzaEditor(true);
                }}
                type={ButtonType.SECONDARY}
              >
                {buttonLabels.mezzaNew}
              </Button>
            </div>
          )}
        </TableControllers>
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredMezzas}
          columns={MEZZA_COMMON_COLUMNS}
          actions={[
            {
              title: 'Infó',
              actionHandler: onViewDetails,
              minWidth: 60,
              width: 10
            }
          ]}
          defaultSortColumn="id"
          defaultSortOrder={SortOption.DESC}
        />
      </Layout>
      {showMezzaEditor && <MezzaForm refresh={refresh} onExit={onCloseEditForm} />}
    </>
  );
};

const MEZZA_COMMON_COLUMNS: TableColumnConfig<Mezza>[] = [
  {
    field: 'id',
    headerName: 'Sorszám',
    width: 10,
    minWidth: 100,
    isSortable: true,
    comparator: numberComparator
  },
  {
    field: 'officeNameToDisplay',
    headerName: 'Iroda',
    width: 15,
    minWidth: 160,
    isSortable: true
  },
  {
    field: 'address',
    headerName: 'Cím',
    width: 'auto',
    minWidth: 180,
    isSortable: true
  },
  {
    field: 'updated_at',
    headerName: 'Módosítva',
    width: 15,
    minWidth: 100,
    isSortable: true,
    renderer: (val) => <>{new Date(val).toLocaleDateString()}</>
  },
  {
    field: 'state',
    headerName: 'Státusz',
    width: 15,
    minWidth: 120,
    isSortable: true,
    renderer: (_status, mezza) => <MezzaStatus mezza={mezza} />,
    comparator: mezzaStateComparator
  },
  {
    field: 'id',
    headerName: 'Adatlap',
    width: 10,
    minWidth: 60,
    renderer: (_id, mezza) => <MezzaDetailOpener mezza={mezza} />
  }
];

export default Common;
