import immoctoIcon from './icon-immocto.png';
import kironIcon from './icon-kiron.png';
import brownIcon from './icon-brown.png';
import immocto from './immocto.png';
import kiron from './kiron.png';
import soluzioni from './soluzioni.png';
import useData from '../../hooks/useData';
import { fetchHighlightedOffices } from '../../requests';

import './Cards.scss';

const Cards = () => {
  const [highlightedOffices] = useData(fetchHighlightedOffices);

  return (
    <div className="cards">
      {highlightedOffices.map((office, index) => (
        <div className="card" key={index}>
          {CARD_STATIC_DATA[index].topContent}
          <div
            style={{ border: `1px solid ${CARD_STATIC_DATA[index].color}` }}
            className="card-border"
          >
            <div className="card-content">
              <div>Iroda neve</div>
              <div>{office.name}</div>
              <div>Cím</div>
              <div>{office.address}</div>
              <div>Irodavezető</div>
              <div>{office.managerName}</div>
              <div>Telefonszám</div>
              <div>{office.managerPhone}</div>
              <div>E-mail cím</div>
              <div>
                <a href={`mailto:${office.managerEmail}`}>{office.managerEmail}</a>
              </div>
              <div>Irodai telefonszám</div>
              <div>{office.office_phone}</div>
              <div>Irodai E-mail cím</div>
              <div>
                <a href={`mailto:${office.office_email}`}>{office.office_email}</a>
              </div>
            </div>
            <div className="card-bottom">
              {CARD_STATIC_DATA[index].bottomContent && CARD_STATIC_DATA[index].bottomContent}
            </div>
            <div
              style={{
                backgroundColor: `${CARD_STATIC_DATA[index].color}45`,
                height: '45px'
              }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

const CARD_STATIC_DATA = [
  {
    id: 1,
    color: '#9CC0F9',
    topContent: (
      <div className="card-top">
        <img src={kironIcon} alt="" />
        Hitelügyintézés
      </div>
    ),
    bottomContent: <img src={kiron} alt="" />
  },
  {
    id: 2,
    color: '#F3C300',
    topContent: (
      <div className="card-top">
        <img src={immoctoIcon} alt="" />
        Ingatlankezelés, bérbeadás, <br />
        szálláshely szolgáltatás
      </div>
    ),
    bottomContent: <img src={immocto} alt="" />
  },
  {
    id: 3,
    color: '#A28358',
    topContent: (
      <div className="card-top">
        <img src={brownIcon} alt="" />
        Ipari és kereskedelmi ingatlanok
      </div>
    ),
    bottomContent: <img src={soluzioni} alt="" />
  }
];

export default Cards;
