import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import PasswordUpdateForm from '../components/PasswordUpdateForm/PasswordUpdateForm';
import Popup from '../components/Popup/Popup';
import { fetchResetPassword } from '../requests';
import { AppRoute, ButtonType } from '../types/enums';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [newPasswordValidator, setNewPasswordValidator] = useState<{
    validate: () => boolean;
  }>({
    validate: () => false
  });
  const { passwordResetToken } = useParams<{ passwordResetToken: string }>();
  const [password, setPassword] = useState('');
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);

  useEffect(() => {
    if (!passwordResetToken) {
      navigate(AppRoute.LOGIN);
    }
  }, []);

  const onReset = useCallback(async () => {
    if (newPasswordValidator.validate() && passwordResetToken) {
      fetchResetPassword(password, passwordResetToken)
        .then(() => {
          setIsResetSuccessful(true);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [password, newPasswordValidator]);

  return (
    <Layout bgSrc="../images/hero-image.jpg">
      {isResetSuccessful ? (
        <Popup
          show
          title="Jelszó megváltoztatása"
          permanent
          footerButtons={[
            {
              title: 'Vissza a címlapra',
              action: () => navigate(AppRoute.LOGIN),
              type: ButtonType.PRIMARY
            }
          ]}
        >
          <p>Jelszavát sikeresen megváltoztatta. Kérjük, lépjen be új jelszavával!</p>
        </Popup>
      ) : (
        <Popup
          show
          title="Jelszó megváltoztatása"
          permanent
          footerButtons={[
            {
              title: 'jelszó megváltoztatása',
              action: onReset,
              type: ButtonType.PRIMARY
            }
          ]}
        >
          <p className="mb-25">Kérjük, adja meg új jelszavát.</p>
          <PasswordUpdateForm setPassword={setPassword} setValidator={setNewPasswordValidator} />
        </Popup>
      )}
    </Layout>
  );
};

export default ResetPassword;
