import { InputHTMLAttributes } from 'react';
import styles from './TextArea.module.scss';

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  value: string;
  label?: string;
  rows?: number;
  error?: string;
  setValue?: (val: string) => void;
}

const TextArea = ({
  setValue = () => {},
  label = '',
  rows = 3,
  error = '',
  ...rest
}: TextAreaProps) => {
  return (
    <div className={error ? styles.error : ''}>
      {label && <label htmlFor={rest.id}>{label}</label>}
      <textarea
        name={rest.id}
        rows={rows}
        onChange={(e) => setValue(e.target.value)}
        className={styles.textarea}
        {...rest}
      />
      {error && <div className={styles.errorText}>{error}</div>}
    </div>
  );
};

export default TextArea;
