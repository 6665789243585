import { ReactNode, useEffect, useState } from 'react';

import { DEFAULT_VISIBLE_ROW_COUNT } from '../../data/constants';
import Dropdown from '../Dropdown/Dropdown';
import Search from '../Search/Search';

import styles from './TableController.module.scss';

interface TableControllersProps {
  onItemsPerPageChanged: (val: number) => void;
  onQueryChange: (val: string) => void;
  searchTitle: string;
  children?: ReactNode;
  renderFilters?: () => ReactNode;
  resetFilters?: () => void;
}

const TableControllers = ({
  onItemsPerPageChanged,
  onQueryChange,
  searchTitle,
  children = null,
  renderFilters = () => null,
  resetFilters
}: TableControllersProps) => {
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_VISIBLE_ROW_COUNT);

  useEffect(() => {
    onItemsPerPageChanged(itemsPerPage);
  }, [itemsPerPage, onItemsPerPageChanged]);

  return (
    <div className={styles.group} style={{ whiteSpace: 'nowrap' }}>
      <div>
        <Search label={searchTitle} onChange={onQueryChange} resetFilters={resetFilters} />
        {renderFilters()}
      </div>
      {children}
      <Dropdown
        options={PAGINATION_OPTIONS}
        value={`${itemsPerPage} db`}
        setValue={(val) => setItemsPerPage(Number(val))}
      />
    </div>
  );
};

const PAGINATION_OPTIONS = [
  {
    key: '10',
    value: '10 db'
  },
  {
    key: '50',
    value: '50 db'
  },
  {
    key: '100',
    value: '100 db'
  },
  {
    key: '200',
    value: '200 db'
  }
];

export default TableControllers;
